import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { supabase } from "./supabaseClient";
import {
  Box,
  Container,
  Spinner,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Grid,
  GridItem,
  Icon,
  Select,
  Flex,
} from "@chakra-ui/react";
import OfferManagement from "./OfferManagement";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import CustomerActivities from "./CustomerActivities";
import CustomerMachines from "./CustomerMachines";
import { isPWA } from "./App";
import { cities } from "./CustomerForm";

const formFields = {
  name: "Firma Adı",
  email: "Email",
  phone: "Telefon",
  address: "Adres",
  city: "Şehir",
  ilce: "İlçe",
  primary_people: "Birincil Yetkili İsim",
  primary_people_pos: "Birincil Yetkili Pozisyon",
  primary_people_phone: "Birincil Yetkili Telefon",
  primary_people_email: "Birincil Yetkili Email",
  secondary_people: "İkincil Yetkili İsim",
  secondary_people_pos: "İkincil Yetkili Pozisyon",
  secondary_people_phone: "İkincil Yetkili Telefon",
  secondary_people_email: "İkincil Yetkili Email",
};

const CustomerDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [uid, setUid] = useState("");
  const [users, setUsers] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [createdFrom, setCreatedFrom] = useState("");

  const [customerStatus, setCustomerStatus] = useState("");

  const sortedCities = [
    "İstanbul",
    "Ankara",
    "İzmir",
    "Adana",
    "Bursa",
    "Gaziantep",
    "Kayseri",
    "Konya",
    "Kocaeli",
    "Sakarya",
    ...cities.filter(
      (city) =>
        ![
          "İstanbul",
          "Ankara",
          "İzmir",
          "Adana",
          "Bursa",
          "Gaziantep",
          "Kayseri",
          "Konya",
          "Kocaeli",
          "Sakarya",
        ].includes(city)
    ),
  ];

  const cancelRef = React.useRef();

  const isValidUUID = (uuid) => {
    const regex =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return regex.test(uuid);
  };

  useEffect(() => {
    const fetchUserUid = async () => {
      const userData = await supabase.auth.getUserIdentities();
      console.log(userData);
      setUid(userData.data.identities[0].id);
    };
    fetchUserUid();

    const fetchCustomer = async () => {
      setLoading(true);
      const { data, error } = await supabase
        .from("customers")
        .select("*")
        .eq("id", id)
        .single();
      if (error) {
        console.error("Error fetching customer:", error);
      } else {
        setCustomer(data);
        setCreatedFrom(data.created_from);
        setCustomerStatus(data.customer_status);
        //setNotes(data.notes);
      }
      setLoading(false);
    };
    fetchCustomer();
  }, [id]);

  useEffect(() => {
    const checkAdmin = async () => {
      if (!uid) return;
      const { data: userData, error } = await supabase
        .from("users_data")
        .select("*")
        .eq("user_uid", uid)
        .single();
      if (error) {
        console.error("Error fetching user role:", error);
      } else {
        setIsAdmin(userData?.position === "admin");
      }
    };
    checkAdmin();
  }, [uid]);

  useEffect(() => {
    const fetchUsers = async () => {
      if (!isAdmin) return;
      const { data, error } = await supabase.from("users_data").select("*");
      if (error) {
        console.error("Error fetching users:", error);
      } else {
        setUsers(data);
      }
    };
    fetchUsers();
  }, [isAdmin]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (!customer) {
    return <Box>No customer found</Box>;
  }

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleDelete = () => {
    onOpen();
  };

  const confirmDelete = async () => {
    try {
      const { error } = await supabase
        .from("customers")
        .delete()
        .eq("id", id)
        .single();

      if (error) {
        console.error("Error deleting customer:", error);
      } else {
        navigate("/customers");
        console.log("Customer deleted successfully!");
      }
    } catch (error) {
      console.error("Error deleting customer:", error);
    } finally {
      onClose();
    }
  };

  const handleUpdate = async () => {
    try {
      const updates = {};
      for (const [key] of Object.entries(formFields)) {
        const inputElement = document.getElementById(`${key}Input`);
        if (inputElement && inputElement.value !== customer[key]) {
          updates[key] = inputElement.value;
        }
      }

      if (customerStatus !== customer.customer_status) {
        updates.customer_status = customerStatus;
      }
      /* if (customerStatus !== customer.notes) {
        updates.notes = notes;
      }*/

      if (isAdmin) {
        const createdFromElement = document.getElementById("createdFromInput");
        if (
          createdFromElement &&
          createdFromElement.value !== customer.created_from
        ) {
          const newCreatedFrom = createdFromElement.value;
          if (!isValidUUID(newCreatedFrom)) {
            console.error("Invalid UUID format for created_from field");
            return;
          }
          updates.created_from = newCreatedFrom;
        }
      }

      if (Object.keys(updates).length > 0) {
        const { error } = await supabase.rpc("update_customer", {
          p_id: id,
          p_name: updates.name || null,
          p_email: updates.email || null,
          p_phone: updates.phone || null,
          p_address: updates.address || null,
          p_city: updates.city || null,
          p_primary_people: updates.primary_people || null,
          p_primary_people_pos: updates.primary_people_pos || null,
          p_primary_people_phone: updates.primary_people_phone || null,
          p_primary_people_email: updates.primary_people_email || null,
          p_secondary_people: updates.secondary_people || null,
          p_secondary_people_pos: updates.secondary_people_pos || null,
          p_secondary_people_phone: updates.secondary_people_phone || null,
          p_secondary_people_email: updates.secondary_people_email || null,
          p_customer_status: updates.customer_status || null,
          p_created_from: updates.created_from || null,
          p_notes: updates.notes || null,
          p_ilce: updates.ilce || null,
        });

        if (error) {
          console.error("Error updating customer:", error);
        } else {
          console.log("Customer updated successfully!");
          setCustomer({ ...customer, ...updates }); // Update local customer data
          setIsEditing(false);
        }
      } else {
        setIsEditing(false);
      }
    } catch (error) {
      console.error("Error updating customer:", error);
    }
  };

  return (
    <Box>
      <Box bg="gray.50"  py={{ base: 4, lg: 8 }} mb={6} borderRadius="md" boxShadow="md">
        <Container maxW="container.xl">
          <Flex justifyContent="space-between" alignItems="center">
            <Box textAlign="center" flex="1">
              <Heading
                as="h2"
                size={{ base: "lg", lg: "2xl" }}
                color="gray.700"
              >
                {customer.name}
              </Heading>
            </Box>
          </Flex>
        </Container>
      </Box>

      <Container maxW="container.xl" p={0}>
        <Tabs
          variant="enclosed"
          colorScheme="gray"
          isLazy
          defaultIndex={0}
          size={"sm"}
        >
          <TabList mb="1em" gap={2}>
            <Tab
              _selected={{ color: "white", bg: "gray.500" }}
              p={{ base: 2, lg: 5 }}
              fontSize={{ base: "sm", lg: "lg" }}
            >
              Aktiviteler
            </Tab>
            <Tab
              _selected={{ color: "white", bg: "gray.500" }}
              p={{ base: 2, lg: 5 }}
              fontSize={{ base: "sm", lg: "lg" }}
            >
              Müşteri Bilgileri
            </Tab>
            <Tab
              _selected={{ color: "white", bg: "gray.500" }}
              p={{ base: 2, lg: 5 }}
              fontSize={{ base: "sm", lg: "lg" }}
            >
              Makinalar
            </Tab>
            <Tab
              _selected={{ color: "white", bg: "gray.500" }}
              p={{ base: 2, lg: 5 }}
              fontSize={{ base: "sm", lg: "lg" }}
            >
              Teklifler
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel p={0}>
              <Box
                bg={isPWA ? "transparent" : "white"}
                borderRadius="md"
                boxShadow={isPWA ? "transparent" : "lg"}
                p={isPWA ? 0 : 5}
              >
                <CustomerActivities id={id} uid={uid} />
              </Box>
            </TabPanel>
            <TabPanel p={0}>
              <Box bg="white" borderRadius="md" boxShadow="lg" p={5}>
                <Grid
                  templateColumns={{
                    base: "repeat(1, 1fr)",
                    md: "repeat(2, 1fr)",
                    lg: "repeat(3, 1fr)",
                  }}
                  gap={6}
                >
                  {Object.entries(formFields).map(([key, label]) => (
                    <GridItem key={key}>
                      <FormControl isReadOnly={!isEditing}>
                        <FormLabel fontWeight="bold" color="blue.700">
                          {label}
                        </FormLabel>
                        {key === "city" ? (
                          <Select
                            id={`${key}Input`}
                            value={customer[key]}
                            onChange={(e) => {
                              if (isEditing) {
                                setCustomer({
                                  ...customer,
                                  [key]: e.target.value,
                                });
                              }
                            }}
                            isDisabled={!isEditing}
                            border={isEditing ? "2px solid" : "1px solid"}
                            borderColor={isEditing ? "blue.300" : "gray.200"}
                            borderRadius="md"
                            bg={isEditing ? "white" : "gray.50"}
                          >
                            {sortedCities.map((city) => (
                              <option key={city} value={city}>
                                {city}
                              </option>
                            ))}
                          </Select>
                        ) : (
                          <Input
                            id={`${key}Input`}
                            defaultValue={customer[key]}
                            border={isEditing ? "2px solid" : "1px solid"}
                            borderColor={isEditing ? "blue.300" : "gray.200"}
                            borderRadius="md"
                            bg={isEditing ? "white" : "gray.50"}
                            as={key === "address" ? "textarea" : "input"}
                            resize={key === "address" ? "vertical" : "none"}
                            minHeight={key === "address" ? "100px" : "auto"}
                            _hover={
                              isEditing ? { borderColor: "blue.400" } : {}
                            }
                          />
                        )}
                      </FormControl>
                    </GridItem>
                  ))}
                  <GridItem colSpan={1}>
                    <FormControl isReadOnly={!isEditing}>
                      <FormLabel fontWeight="bold" color="blue.700">
                        Durum
                      </FormLabel>
                      <Select
                        id="customerStatusInput"
                        value={customerStatus}
                        onChange={(e) => setCustomerStatus(e.target.value)}
                        disabled={!isEditing}
                        border={isEditing ? "2px solid" : "1px solid"}
                        borderColor={isEditing ? "blue.300" : "gray.200"}
                        borderRadius="md"
                        bg={isEditing ? "white" : "gray.50"}
                      >
                        <option value="aktif">Aktif</option>
                        <option value="eski">Eski</option>
                        <option value="potansiyel">Potansiyel</option>
                      </Select>
                    </FormControl>
                  </GridItem>
                  {/* 
                  {(isAdmin || createdFrom === uid) && (
                    <GridItem colSpan={{ base: 1, md: 2, lg: 3 }}>
                      <FormControl isReadOnly={!isEditing}>
                        <FormLabel fontWeight="bold" color="blue.700">
                          Görüşme Notu
                        </FormLabel>
                        <Textarea
                          id="notesInput"
                          value={notes}
                          onChange={(e) => setNotes(e.target.value)}
                          disabled={!isEditing}
                          border={isEditing ? "2px solid" : "1px solid"}
                          borderColor={isEditing ? "blue.300" : "gray.200"}
                          borderRadius="md"
                          bg={isEditing ? "white" : "gray.50"}
                          rows={4}
                        />
                      </FormControl>
                    </GridItem>
                  )}*/}
                  <GridItem colSpan={1}>
                    <FormControl isReadOnly={true}>
                      <FormLabel fontWeight="bold" color="blue.700">
                        Oluşturulma Zamanı
                      </FormLabel>
                      <Input
                        value={new Date(customer.created_at).toLocaleString()}
                        border="1px solid"
                        borderColor="gray.200"
                        borderRadius="md"
                        bg="gray.50"
                        disabled={true}
                      />
                    </FormControl>
                  </GridItem>
                  {isAdmin && (
                    <GridItem colSpan={1}>
                      <FormControl>
                        <FormLabel fontWeight="bold" color="blue.700">
                          Sorumlu
                        </FormLabel>
                        <Select
                          id="createdFromInput"
                          value={createdFrom}
                          onChange={(e) => setCreatedFrom(e.target.value)}
                          disabled={!isEditing}
                          border={isEditing ? "2px solid" : "1px solid"}
                          borderColor={isEditing ? "blue.300" : "gray.200"}
                          borderRadius="md"
                          bg={isEditing ? "white" : "gray.50"}
                        >
                          {users.map((user) => (
                            <option key={user.user_uid} value={user.user_uid}>
                              {user.name} {user.last_name}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                  )}
                </Grid>

                {(isAdmin || createdFrom === uid) && (
                  <Box
                    mt={8}
                    p={4}
                    borderWidth="1px"
                    borderRadius="md"
                    bg="red.50"
                  >
                    <Heading size="md" mb={4} color="red.600">
                      Tehlikeli Bölge
                    </Heading>
                    <Button
                      colorScheme="red"
                      onClick={handleDelete}
                      rightIcon={<Icon as={FaTrash} />}
                      width="100%"
                      borderRadius="md"
                    >
                      Müşteriyi Sil
                    </Button>
                  </Box>
                )}

                <Box mt={4}>
                  {(isAdmin || createdFrom === uid) && (
                    <Button
                      colorScheme="blue"
                      onClick={isEditing ? handleUpdate : handleEdit}
                      width="100%"
                      size="lg"
                      borderRadius="md"
                      boxShadow="md"
                    >
                      {isEditing ? "Güncelle" : "Düzenle"}
                    </Button>
                  )}
                </Box>
              </Box>
            </TabPanel>
            <TabPanel p={0}>
              <Box
                bg={isPWA ? "transparent" : "white"}
                borderRadius="md"
                boxShadow={isPWA ? "transparent" : "lg"}
                p={isPWA ? 0 : 5}
              >
                <CustomerMachines customerId={id} />
              </Box>
            </TabPanel>
            <TabPanel p={0}>
              <Box
                bg={isPWA ? "transparent" : "white"}
                borderRadius="md"
                boxShadow={isPWA ? "transparent" : "lg"}
                p={isPWA ? 0 : 5}
              >
                <OfferManagement customerId={id} />
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent borderRadius="md">
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Müşteriyi Sil
            </AlertDialogHeader>

            <AlertDialogBody>
              Bu müşteriyi silmek istediğinizden emin misiniz?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} borderRadius="md">
                Vazgeç
              </Button>
              <Button
                colorScheme="red"
                onClick={confirmDelete}
                ml={3}
                borderRadius="md"
              >
                Sil
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default CustomerDetail;

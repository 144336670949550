import React, { useState } from 'react';
import { Box, Button, FormControl, FormLabel, Input, VStack, Text } from "@chakra-ui/react";
import { supabase } from "./supabaseClient";
import { useNavigate, useLocation } from 'react-router-dom';


export default function ResetPassword() {
  const [resetCode, setResetCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email;

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    // Önce kodu doğrulayın
    const { error: verifyError } = await supabase.auth.verifyOtp({
      email,
      token: resetCode,
      type: 'recovery'
    });

    if (verifyError) {
      setMessage('Invalid or expired code. Please try again.');
      setLoading(false);
      return;
    }

    // Kodu doğruladıktan sonra şifreyi güncelleyin
    const { error: updateError } = await supabase.auth.updateUser({ 
      password: newPassword 
    });

    if (updateError) {
      setMessage('Error updating password. Please try again.');
    } else {
      setMessage('Password updated successfully');
      setTimeout(() => navigate('/auth'), 2000);
    }
    setLoading(false);
  };

  return (
    <Box maxWidth="400px" margin="auto" mt={8} p={5}>
      <form onSubmit={handleResetPassword}>
        <VStack spacing={4}>
          <FormControl>
            <FormLabel>6-Digit Reset Code</FormLabel>
            <Input type="text" value={resetCode} onChange={(e) => setResetCode(e.target.value)} required />
          </FormControl>
          <FormControl>
            <FormLabel>New Password</FormLabel>
            <Input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
          </FormControl>
          <Button type="submit" colorScheme="blue" isLoading={loading}>Reset Password</Button>
          {message && <Text color={message.includes('successfully') ? 'green.500' : 'red.500'}>{message}</Text>}
        </VStack>
      </form>
    </Box>
  );
}
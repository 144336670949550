import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { SessionProvider } from "./SessionContext";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { ToastProvider } from "./ToastProvider";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ToastProvider>
    <SessionProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </SessionProvider>
  </ToastProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorkerRegistration.register();
reportWebVitals();

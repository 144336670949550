import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Grid,
  LinkBox,
  LinkOverlay,
  GridItem,
  Icon,
  Spinner,
  Center,
  Container,
  Heading,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { AddIcon, CalendarIcon, Search2Icon, TimeIcon } from "@chakra-ui/icons";
import { AiOutlineDashboard, AiOutlineUserAdd } from "react-icons/ai";
import { supabase } from "./supabaseClient";

const HomePage = () => {
  const [uid, setUid] = useState("");
  const [user, setUser] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  const bgColor = useColorModeValue("white", "white");
  const cardBgColor = useColorModeValue("gray.50", "gray.600");
  const borderColor = useColorModeValue("gray.200", "gray.500");
  useEffect(() => {
    const fetchUserUid = async () => {
      const userData = await supabase.auth.getUserIdentities();
      if (
        userData.data &&
        userData.data.identities &&
        userData.data.identities.length > 0
      ) {
        setUid(userData.data.identities[0].id);
      }
    };
    fetchUserUid();
  }, []);

  useEffect(() => {
    const fetchUser = async () => {
      if (!uid) return; // uid boşsa sorguyu yapmayın
      const { data, error } = await supabase
        .from("users_data")
        .select("*")
        .eq("user_uid", uid)
        .single();
      if (error) {
        console.error("Error fetching user:", error);
      } else {
        setUser(data);
      }
      setIsLoading(false);
    };

    if (uid) {
      fetchUser();
    }
  }, [uid]);

  if (isLoading) {
    return (
      <Center height="100vh">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Center>
    );
  }

  const MenuCard = ({ icon, title, href, bgIconColor }) => (
    <LinkBox
      as="article"
      borderWidth="1px"
      borderRadius="lg"
      borderColor={borderColor}
      bg={cardBgColor}
      _hover={{
        boxShadow: "lg",
        transform: "translateY(-2px)",
        transition: "all 0.2s",
      }}
    >
      <VStack spacing={3} align="center" justify="center" height="100%" p={5}>
        <Box
          backgroundColor={bgIconColor}
          borderRadius="full"
          p={3}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {icon}
        </Box>
        <LinkOverlay href={href}>
          <Text fontWeight="600" fontSize="lg">
            {title}
          </Text>
        </LinkOverlay>
      </VStack>
    </LinkBox>
  );

  return (
    <Box bg={bgColor} minHeight="80vh" py={10}>
      <Container maxW="container.xl">
        {user != null && (
          <VStack spacing={5}>
            <Heading as="h1" size="lg" textAlign="center" mb={6}>
              Hoş Geldiniz, {user.name}
            </Heading>
            <Grid
              templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
              gap={6}
              width="100%"
            >
              <GridItem>
                <MenuCard
                  icon={<AddIcon boxSize={6} color="white" />}
                  title="Müşteri Ekle"
                  href="/add-customer"
                  bgIconColor="blue.500"
                />
              </GridItem>
              {user?.position === "admin" && (
                <>
                  <GridItem>
                    <MenuCard
                      icon={
                        <Icon as={AiOutlineUserAdd} boxSize={6} color="white" />
                      }
                      title="Kullanıcı Ekle"
                      href="/admin"
                      bgIconColor="green.500"
                    />
                  </GridItem>
                  <GridItem>
                    <MenuCard
                      icon={
                        <Icon as={AiOutlineDashboard} boxSize={6} color="white" />
                      }
                      title="Dashboard"
                      href="/dashboard"
                      bgIconColor="cyan.500"
                    />
                  </GridItem>
                </>
              )}
              <GridItem>
                <MenuCard
                  icon={<Search2Icon boxSize={6} color="white" />}
                  title="Müşteri Görüntüle"
                  href="/customers"
                  bgIconColor="purple.500"
                />
              </GridItem>
              <GridItem>
                <MenuCard
                  icon={<CalendarIcon boxSize={6} color="white" />}
                  title="Günlük İşlemler"
                  href="/daily-activities"
                  bgIconColor="yellow.500"
                />
              </GridItem>
              <GridItem>
                <MenuCard
                  icon={<TimeIcon boxSize={6} color="white" />}
                  title="Son İşlemler"
                  href="/last-activities"
                  bgIconColor="orange.500"
                />
              </GridItem>
            </Grid>
          </VStack>
        )}
      </Container>
    </Box>
  );
};

export default HomePage;

import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
} from "@chakra-ui/react";
import ReactMarkdown from "react-markdown";
import { supabase } from "./supabaseClient";

const UpdateNotes = () => {
  const [updateNotes, setUpdateNotes] = useState([]);
  const [expandedIndex, setExpandedIndex] = useState(0);

  useEffect(() => {
    const fetchUpdateNotes = async () => {
      const { data, error } = await supabase
        .from("update_notes")
        .select("*")
        .order("updated_at", { ascending: false });

      if (error) {
        console.error("Error fetching update notes:", error);
      } else {
        setUpdateNotes(data);
      }
    };

    fetchUpdateNotes();
  }, []);

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString("tr-TR", options);
  };

  return (
    <Box bg="gray.50" p={4} borderRadius="lg" boxShadow="md">
      <Heading as="h2" size="lg" mb={4}>
        Güncelleme Notları
      </Heading>
      <Accordion
        allowToggle
        index={expandedIndex}
        onChange={(index) => setExpandedIndex(index)}
      >
        {updateNotes.map((note, index) => (
          <AccordionItem key={note.id}>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left" fontSize={{base:"sm",lg:"lg"}}>
                  {note.title}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Box className="markdown-content">
                <ReactMarkdown>{note.content}</ReactMarkdown>
              </Box>
              <Text fontSize="sm" color="gray.500" mt={2}>
                Son güncelleme: {formatDate(note.updated_at)}
              </Text>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
};

export default UpdateNotes;

import React, { createContext, useState, useEffect } from "react";
import { supabase } from "./supabaseClient";

export const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userPosition, setUserPosition] = useState(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      if (session) {
        fetchUserPosition(session.user.id);
      } else {
        setLoading(false);
      }
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      if (session) {
        fetchUserPosition(session.user.id);
      } else {
        setUserPosition(null);
        setLoading(false);
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  const fetchUserPosition = async (userId) => {
    const { data, error } = await supabase
      .from("users_data")
      .select("position")
      .eq("user_uid", userId)
      .single();

    if (error) {
      console.error("Error fetching user position:", error);
    } else if (data) {
      setUserPosition(data.position);
    }
    setLoading(false);
  };

  return (
    <SessionContext.Provider value={{ session, loading, userPosition }}>
      {children}
    </SessionContext.Provider>
  );
};

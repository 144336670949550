import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Text,
  Link,
  Image,
  Container,
} from "@chakra-ui/react";
import { supabase } from "./supabaseClient";
import { useNavigate } from "react-router-dom";

export default function Auth() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const navigate = useNavigate();

  const handleSignIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    if (error) setMessage(error.message);
    setLoading(false);
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${window.location.origin}/reset-password`,
    });
    if (error) {
      setMessage(error.message);
    } else {
      setMessage(
        "Password reset email sent. Check your inbox for the 6-digit code."
      );
      navigate("/reset-password", { state: { email } });
    }
    setLoading(false);
  };
  if (isForgotPassword) {
    return (
      <Box maxWidth="400px" margin="auto" mt={8}>
        <form onSubmit={handleResetPassword}>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </FormControl>
            <Button type="submit" colorScheme="blue" isLoading={loading}>
              Send Reset Code
            </Button>
            <Button onClick={() => setIsForgotPassword(false)}>
              Back to Login
            </Button>
            {message && (
              <Text color={message.includes("sent") ? "green.500" : "red.500"}>
                {message}
              </Text>
            )}
          </VStack>
        </form>
      </Box>
    );
  }

  return (
    <Container
      maxW="md"
      justifyContent="center"
      alignContent="center"
      display="flex"
      h="100vh"
      w="100vw"
    >
      <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} w={"100%"}>
        <Box margin="auto" p={2} alignItems={"center" }  w={"100%"}>
          <Box
            backgroundColor={"gray.500"}
            p={2}
            my={5}
            borderRadius={"md"}
            justifyContent={"center"}
            display={"flex"}
          >
            <Link to="/">
              <Image
                src={"/logo-1.svg"}
              />
            </Link>
          </Box>

          <form onSubmit={handleSignIn}>
            <VStack spacing={4}>
              <FormControl>
                <FormLabel w={"100%"} textAlign={"center"}>
                  Email
                </FormLabel>
                <Input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </FormControl>
              <FormControl>
                <FormLabel w={"100%"} textAlign={"center"}>
                  Password
                </FormLabel>
                <Input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </FormControl>
              <Button type="submit" colorScheme="blue" isLoading={loading}>
                Giriş Yap
              </Button>
              <Link onClick={() => setIsForgotPassword(true)}>
                Şifremi Unuttum?
              </Link>
              {message && <Text color="red.500">{message}</Text>}
            </VStack>
          </form>
        </Box>
      </Box>
    </Container>
  );
}

import React, { useEffect, useState } from "react";
import { supabase } from "./supabaseClient";
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  Select,
  VStack,
  Alert,
  AlertIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  SimpleGrid,
  Avatar,
  HStack,
  Tag,
  TagLeftIcon,
  TagLabel,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { FaUserPlus, FaUsers, FaPhoneAlt, FaBriefcase } from "react-icons/fa";
import { useToastContext } from "./ToastProvider";
import { Tooltip } from "@chakra-ui/react";


const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [activeTab, setActiveTab] = useState(0);

  const [newUser, setNewUser] = useState({
    email: "",
    password: "",
    position: "servis",
    name: "",
    last_name: "",
    number: "",
  });
  const [editUser, setEditUser] = useState({
    position: "servis",
    name: "",
    last_name: "",
    number: "",
  });
  const [error, setError] = useState("");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const showToast = useToastContext();

  const activeUsers = users.filter((user) => user.position !== "inactive");
  const inactiveUsers = users.filter((user) => user.position === "inactive");

  const formatPosition = (position) => {
    if (position) {
      switch (position.toLowerCase()) {
        case "servis":
          return "Servis";
        case "satis":
          return "Satış";
        case "admin":
          return "Admin";
        case "inactive":
          return "İnaktif";
        default:
          return position.toUpperCase();
      }
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      const { data: authUsers, error: authError } = await supabase.rpc(
        "get_auth_users"
      );
      if (authError) {
        console.error("Error fetching auth users:", authError);
        showToast({
          title: "Hata!",
          description: "Error fetching auth users:",
          status: "error",
        });
        return;
      }

      const { data: usersData, error: usersDataError } = await supabase
        .from("users_data")
        .select("*");
      if (usersDataError) {
        showToast({
          title: "Hata!",
          description: "Error fetching users_data:",
          status: "error",
        });
        console.error("Error fetching users_data:", usersDataError);
        return;
      }

      const combinedUsers = authUsers.map((authUser) => {
        const userData =
          usersData.find((user) => user.user_uid === authUser.id) || {};
        return {
          id: authUser.id,
          email: authUser.email,
          ...userData,
        };
      });

      setUsers(combinedUsers);
    };

    fetchUsers();
  }, [showToast]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddUser = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const {
        data: { session },
      } = await supabase.auth.getSession();

      if (!session) {
        throw new Error("No active session");
      }

      const response = await fetch("/test", {
        method: "POST",
        headers: {
          authorization: `Bearer ${session.access_token}`,
          "content-type": "application/json",
        },
        body: JSON.stringify({
          email: newUser.email,
          password: newUser.password,
          userData: {
            position: newUser.position,
            name: newUser.name,
            last_name: newUser.last_name,
            number: newUser.number,
          },
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        showToast({
          title: "Hata!",
          description: "Failed to create user.",
          status: "error",
        });
        throw new Error(data.error || "Failed to create user.");
      }
      showToast({
        title: "Başarılı!",
        description: "Kullanıcı başarıyla oluşturuldu!",
        status: "success",
      });

      setNewUser({
        email: "",
        password: "",
        position: "servis",
        name: "",
        last_name: "",
        number: "",
      });

      const { data: newUserData, error: newUserError } = await supabase
        .from("users_data")
        .select("*")
        .eq("user_uid", data.user.id)
        .single();

      if (newUserError) {
        showToast({
          title: "Hata!",
          description: "Error fetching new user data",
          status: "error",
        });
        console.error("Error fetching new user data:", newUserError);
      } else {
        const updatedUsers = [...users, { ...data.user, ...newUserData }];
        setUsers(updatedUsers);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setEditUser({
      email: user.email,
      position: user.position || "servis",
      name: user.name || "",
      last_name: user.last_name || "",
      number: user.number || "",
    });
    onOpen();
  };

  const handleUpdateUser = async () => {
    try {
      const { error: updateError } = await supabase.from("users_data").upsert(
        {
          user_uid: selectedUser.id,
          name: editUser.name,
          last_name: editUser.last_name,
          number: editUser.number,
          position: editUser.position,
        },
        { onConflict: ["user_uid"] }
      );

      if (updateError) throw updateError;

      showToast({
        title: "Başarılı!",
        description: "Kullanıcı başarıyla güncellendi!",
        status: "success",
      });

      const updatedUsers = users.map((user) =>
        user.id === selectedUser.id ? { ...user, ...editUser } : user
      );
      setUsers(updatedUsers);

      // Switch to the appropriate tab
      if (editUser.position === "inactive") {
        setActiveTab(1); // Switch to inactive users tab
      } else if (selectedUser.position === "inactive") {
        setActiveTab(0); // Switch to active users tab
      }

      onClose();
    } catch (error) {
      setError(error.message);
    }
  };

  const handleDeleteUser = async () => {
    try {
      const { error: updateError } = await supabase.from("users_data").upsert(
        {
          user_uid: selectedUser.id,
          name: editUser.name,
          last_name: editUser.last_name,
          number: editUser.number,
          position: "inactive",
        },
        { onConflict: ["user_uid"] }
      );

      if (updateError) throw updateError;

      showToast({
        title: "Başarılı!",
        description: "Kullanıcı başarıyla inaktif yapıldı!",
        status: "success",
      });

      const updatedUsers = users.map((user) =>
        user.id === selectedUser.id
          ? { ...user, ...editUser, position: "inactive" }
          : user
      );
      setUsers(updatedUsers);

      setActiveTab(1); // Switch to inactive users tab

      onClose();
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Box>
      <Heading as="h1" mb={6}>
        Admin Dashboard
      </Heading>
      {error && (
        <Alert status="error" mb={4}>
          <AlertIcon />
          {error}
        </Alert>
      )}

      <Tabs isFitted variant="enclosed">
        <TabList mb="1em">
          <Tab>
            <FaUsers /> Kullanıcılar
          </Tab>
          <Tab>
            <FaUserPlus /> Yeni Kullanıcı Oluştur
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel p="0">
            <Tabs
              variant="soft-rounded"
              colorScheme="blue"
              index={activeTab}
              onChange={(index) => setActiveTab(index)}
            >
              <TabList mb={4} gap={2}>
                <Tab>
                  <Text fontSize={"sm"}>
                    Aktif Kullanıcılar ({activeUsers.length})
                  </Text>
                </Tab>
                <Tab>
                  <Text fontSize={"sm"}>
                    İnaktif Kullanıcılar ({inactiveUsers.length})
                  </Text>
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel p="1">
                  <UserList
                    users={activeUsers}
                    handleEditUser={handleEditUser}
                    formatPosition={formatPosition}
                  />
                </TabPanel>
                <TabPanel p="1">
                  <UserList
                    users={inactiveUsers}
                    handleEditUser={handleEditUser}
                    formatPosition={formatPosition}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </TabPanel>
          <TabPanel>
            <NewUserForm
              newUser={newUser}
              handleInputChange={handleInputChange}
              handleAddUser={handleAddUser}
              isLoading={isLoading}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
        <ModalOverlay />
        <ModalContent mx={5}>
          <ModalHeader>Kullanıcıyı Düzenle</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Ad</FormLabel>
              <Input
                type="text"
                name="name"
                value={editUser.name}
                onChange={handleEditInputChange}
                required
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Soyad</FormLabel>
              <Input
                type="text"
                name="last_name"
                value={editUser.last_name}
                onChange={handleEditInputChange}
                required
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Telefon</FormLabel>
              <InputGroup>
                <InputLeftElement children={<FaPhoneAlt color="gray.300" />} />
                <Input
                  type="text"
                  name="number"
                  value={editUser.number}
                  onChange={handleEditInputChange}
                  required
                />
              </InputGroup>
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Rol</FormLabel>
              <Select
                name="position"
                value={editUser.position}
                onChange={handleEditInputChange}
                required
                icon={<FaBriefcase />}
              >
                <option value="servis">Servis</option>
                <option value="satis">Satış</option>
                <option value="admin">Admin</option>
                <option value="inactive">İnaktif</option>
              </Select>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleUpdateUser}>
              Güncelle
            </Button>
            <Button colorScheme="red" mr={3} onClick={handleDeleteUser}>
              İnaktif Et
            </Button>
            <Button variant="ghost" onClick={onClose}>
              İptal
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

const UserList = ({ users, handleEditUser, formatPosition }) => {
  return (
    <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
      {users.map((user) => (
        <UserCard
          key={user.id}
          user={user}
          handleEditUser={handleEditUser}
          formatPosition={formatPosition}
        />
      ))}
    </SimpleGrid>
  );
};

const UserCard = ({ user, handleEditUser, formatPosition }) => {
  const isInactive = user.position === "inactive";
  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      boxShadow="lg"
      bg={isInactive ? "gray.100" : "white"}
      opacity={isInactive ? 0.7 : 1}
      transition="all 0.3s"
      _hover={{
        transform: "translateY(-5px)",
        boxShadow: "xl",
      }}
      onClick={() => handleEditUser(user)}
      cursor="pointer"
    >
      <Box p={6}>
        <VStack align="start" spacing={3} w="100%">
          <HStack
            spacing={2}
            w="100%"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Avatar size="lg" name={`${user.name} ${user.last_name}`} />
            <VStack align="end" maxW="70%">
              <Heading size="md" fontWeight="semibold" noOfLines={1}>
                {user.name} {user.last_name}
              </Heading>
              <Tooltip label={user.email} placement="bottom" hasArrow>
                <Text color="gray.500" fontSize="sm" noOfLines={1} maxW="100%">
                  {user.email}
                </Text>
              </Tooltip>
            </VStack>
          </HStack>
          <HStack spacing={4} justifyContent="space-between" w="100%">
            <Tag variant="subtle" colorScheme="blue">
              <TagLeftIcon as={FaBriefcase} />
              <TagLabel>{formatPosition(user.position)}</TagLabel>
            </Tag>
            <Tag size="md" variant="subtle" colorScheme="green">
              <TagLeftIcon as={FaPhoneAlt} />
              <TagLabel>{user.number}</TagLabel>
            </Tag>
          </HStack>
        </VStack>
      </Box>
    </Box>
  );
};

const NewUserForm = ({
  newUser,
  handleInputChange,
  handleAddUser,
  isLoading,
}) => {
  return (
    <form onSubmit={handleAddUser}>
      <VStack spacing={5} align="stretch">
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
          <FormControl>
            <FormLabel>E-posta</FormLabel>
            <Input
              type="email"
              name="email"
              value={newUser.email}
              onChange={handleInputChange}
              required
            />
          </FormControl>
          <FormControl>
            <FormLabel>Şifre</FormLabel>
            <Input
              type="password"
              name="password"
              value={newUser.password}
              onChange={handleInputChange}
              required
            />
          </FormControl>
          <FormControl>
            <FormLabel>Ad</FormLabel>
            <Input
              type="text"
              name="name"
              value={newUser.name}
              onChange={handleInputChange}
              required
            />
          </FormControl>
          <FormControl>
            <FormLabel>Soyad</FormLabel>
            <Input
              type="text"
              name="last_name"
              value={newUser.last_name}
              onChange={handleInputChange}
              required
            />
          </FormControl>
          <FormControl>
            <FormLabel>Telefon</FormLabel>
            <InputGroup>
              <InputLeftElement children={<FaPhoneAlt color="gray.300" />} />
              <Input
                type="text"
                name="number"
                value={newUser.number}
                onChange={handleInputChange}
                required
              />
            </InputGroup>
          </FormControl>
          <FormControl>
            <FormLabel>Rol</FormLabel>
            <Select
              name="position"
              value={newUser.position}
              onChange={handleInputChange}
              required
              icon={<FaBriefcase />}
            >
              <option value="servis">Servis</option>
              <option value="satis">Satış</option>
              <option value="admin">Admin</option>
            </Select>
          </FormControl>
        </SimpleGrid>
        <Button
          type="submit"
          colorScheme="blue"
          width="full"
          isLoading={isLoading}
          loadingText="Oluşturuluyor"
          leftIcon={<FaUserPlus />}
        >
          Kullanıcı Oluştur
        </Button>
      </VStack>
    </form>
  );
};

export default AdminDashboard;

import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Flex,
  Image,
  useColorModeValue,
  Container,
  Button,
  Text,
  Spacer,
  Avatar,
  IconButton,
} from "@chakra-ui/react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { supabase } from "./supabaseClient";
import {
  FaClipboardList,
  FaClock,
  FaUsers,
  FaCog,
  FaCalendarDay,
  FaArrowLeft,
} from "react-icons/fa";
import { RiAdminLine } from "react-icons/ri";
import { SessionContext } from "./SessionContext";
import { AiOutlineDashboard } from "react-icons/ai";

const NavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { session, userPosition } = useContext(SessionContext);
  const [userName, setUserName] = useState("");
  const isPWA =
    window.matchMedia("(display-mode: standalone)").matches ||
    window.navigator.standalone;
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.700");

  useEffect(() => {
    const fetchUserName = async () => {
      const { data, error } = await supabase
        .from("users_data")
        .select("name, last_name")
        .eq("user_uid", session.user.id)
        .single();

      if (error) {
        console.error("Error fetching user data:", error);
      } else if (data) {
        setUserName(`${data.name} ${data.last_name}`);
      }
    };

    if (session) {
      fetchUserName();
    }
  }, [session]);

  const handleLogoClick = () => {
    navigate(isPWA ? "/customers" : "/home");
  };

  const isActive = (path) => location.pathname === path;

  const handleBackClick = () => {
    navigate(-1);
  };

  const MenuButton = ({ icon, title, to, color }) => {
    const isActiveRoute = isActive(to);
    return (
      <Button
        as={Link}
        to={to}
        variant={isActiveRoute ? "solid" : "ghost"}
        colorScheme={isActiveRoute ? "gray.300" : "gray"}
        flexDirection="column"
        height="auto"
        py={2}
        px={1}
        bg={isActiveRoute ? "gray.300" : "transparent"}
        width="100%"
      >
        <Box backgroundColor={color + ".500"} borderRadius="full" p={2} mb={1}>
          {icon}
        </Box>
        <Box
          as="span"
          fontSize="2xs"
          textAlign="center"
          whiteSpace="normal"
          display={{ base: "none", sm: "block" }}
          color={"black"}
        >
          {title}
        </Box>
      </Button>
    );
  };

  return (
    <Box
      position="sticky"
      top="0"
      bg={bgColor}
      zIndex="2"
      boxShadow="sm"
      borderBottom="1px"
      borderColor={borderColor}
    >
      <Container maxW="container.lg">
        <Flex
          px={2}
          py={3}
          alignItems="center"
          justifyItems={isPWA ? "center" : "space-between"}
          display={isPWA ? "grid" : "flex"}
        >
          {isPWA && (
            <IconButton
              icon={<FaArrowLeft />}
              onClick={handleBackClick}
              variant="ghost"
              position="absolute"
              left={2}
              top="50%"
              transform="translateY(-50%)"
              aria-label="Go back"
            />
          )}
          <Box
            backgroundColor={"gray.500"}
            p={2}
            w={{ base: "30%", lg: "fit-content" }}
            borderRadius={"md"}
            justifyContent={"center"}
            display={"flex"}
            onClick={handleLogoClick}
            cursor="pointer"
          >
            <Image src={"/logo-1.svg"} />
          </Box>
          <Spacer />
          {!isPWA && (
            <Flex alignItems="center" gap={2}>
              <Button as={Link} to="/settings" variant="ghost" p={5}>
                <Avatar
                  name={userName}
                  mr={2}
                  size={{ base: "xs", lg: "sm" }}
                />
                <Text fontSize={{ base: "sm", lg: "lg" }}>{userName} </Text>
              </Button>
            </Flex>
          )}
        </Flex>
      </Container>
      {isPWA && (
        <Box
          position="fixed"
          bottom="0"
          left="0"
          right="0"
          bg={bgColor}
          borderTop="1px"
          borderColor={borderColor}
          zIndex="2"
        >
          <Container maxW="container.lg" px={0} mb={3}>
            <Flex justifyContent="space-around" py={2} gap={2}>
              <MenuButton
                icon={<FaUsers color="white" />}
                title="Müşteriler"
                to="/customers"
                color="blue"
              />
              <MenuButton
                icon={<FaCalendarDay color="white" />}
                title="Günlük İşlemler"
                to="/daily-activities"
                color="cyan"
              />
              {userPosition !== "servis" && (
                <MenuButton
                  icon={<FaClipboardList color="white" />}
                  title="Müşteri Ekle"
                  to="/add-customer"
                  color="green"
                />
              )}
              {userPosition !== "servis" && (
                <MenuButton
                  icon={<FaClock color="white" />}
                  title="Son İşlemler"
                  to="/last-activities"
                  color="orange"
                />
              )}
              {userPosition === "admin" && (
                <MenuButton
                  icon={<RiAdminLine color="white" />}
                  title="Admin Panel"
                  to="/admin"
                  color="red"
                />
              )}
              {userPosition === "admin" && (
                <MenuButton
                  icon={<AiOutlineDashboard color="white" />}
                  title="Admin Panel"
                  to="/dashboard"
                  color="cyan"
                />
              )}
              <MenuButton
                icon={<FaCog color="white" />}
                title="Ayarlar"
                to="/settings"
                color="purple"
              />
            </Flex>
          </Container>
        </Box>
      )}
    </Box>
  );
};

export default NavBar;
import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  VStack,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Select,
  useColorModeValue,
  Spinner,
  SimpleGrid,
  Grid,
  GridItem,
  Button,
} from "@chakra-ui/react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import { supabase } from "./supabaseClient";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
);

function Dashboard() {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userType, setUserType] = useState("admin");

  const bgColor = useColorModeValue("gray.50", "gray.700");
  const cardBgColor = useColorModeValue("white", "gray.600");
  const textColor = useColorModeValue("gray.800", "white");

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const endDatePlusOne = new Date(endDate);
      endDatePlusOne.setDate(endDatePlusOne.getDate() + 1);
      const endDatePlusOneStr = endDatePlusOne.toISOString().split("T")[0];

      const startDateMinusOne = new Date(startDate);
      startDateMinusOne.setDate(startDateMinusOne.getDate() - 1);
      const startDateMinusOneStr = startDateMinusOne
        .toISOString()
        .split("T")[0];

      console.log(endDatePlusOneStr, startDateMinusOneStr);

      const [customerData, userData, dailyactivityData, customerActivityData] =
        await Promise.all([
          supabase
            .from("customers")
            .select("*")
            .gte("created_at", startDate)
            .lte("created_at", endDatePlusOneStr),
          supabase.from("users_data").select("*").eq("position", userType),
          supabase
            .from("daily_activities")
            .select("*")
            .gte("created_at", startDate)
            .lte("created_at", endDatePlusOneStr),
          supabase
            .from("customer_activities")
            .select("*")
            .gte("created_at", startDate)
            .lte("created_at", endDatePlusOneStr),
        ]);

      const processedData = processData(
        customerData.data,
        userData.data,
        dailyactivityData.data,
        customerActivityData.data,
        userType
      );
      console.log(processedData);
      console.log([
        customerData,
        userData,
        dailyactivityData,
        customerActivityData,
      ]);
      setData(processedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [startDate, endDate, userType]);

  const generateReport = async () => {
    if (!startDate || !endDate) {
      alert("Lütfen başlangıç ve bitiş tarihlerini seçin.");
      return;
    }

    setLoading(true);
    try {
      const {
        data: { session },
      } = await supabase.auth.getSession();

      if (!session) {
        throw new Error("No active session");
      }
      const response = await fetch("/api/generate-report", {
        method: "POST",
        headers: {
          authorization: `Bearer ${session.access_token}`,
          "content-type": "application/json",
        },
        body: JSON.stringify({ startDate, endDate, userType }),
      });

      if (!response.ok) {
        throw new Error("Rapor oluşturma hatası");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `Sunton_CRM_Rapor_${startDate}_${endDate}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Rapor oluşturma hatası:", error);
      alert("Rapor oluşturulurken bir hata oluştu.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (startDate && endDate) {
      fetchData();
    }
  }, [fetchData, startDate, endDate, userType]);

  const processData = (
    customers,
    users,
    dailyactivityData,
    customerActivityData,
    userType
  ) => {
    // Günlük müşteri sayıları
    const dailyCustomers = customers.reduce((acc, customer) => {
      const date = customer.created_at.split("T")[0];
      acc[date] = (acc[date] || 0) + 1;
      return acc;
    }, {});

    // Kullanıcı aktiviteleri
    const userActivities = users.map((user) => ({
      id: user.user_uid,
      name: user.name + " " + user.last_name,
      customersCreated: customers.filter(
        (c) => c.created_from === user.user_uid && user.position === userType
      ).length,
      dailyActivities: dailyactivityData.filter(
        (a) => a.user_id === user.user_uid && user.position === userType
      ).length,
      customerActivities: customerActivityData.filter(
        (a) => a.created_from === user.user_uid && user.position === userType
      ).length,
      position: user.position,
    }));

    // Müşteri şehirleri
    const customerCities = customers.reduce((acc, customer) => {
      acc[customer.city] = (acc[customer.city] || 0) + 1;
      return acc;
    }, {});

    return {
      dailyCustomers: Object.entries(dailyCustomers)
        .map(([date, count]) => ({ date, count }))
        .sort((a, b) => new Date(a.date) - new Date(b.date)),
      userActivities,
      customerCities: Object.entries(customerCities).map(([city, count]) => ({
        city,
        count,
      })),
    };
  };

  const dailyCustomersChartData = {
    labels: data ? data.dailyCustomers.map((d) => d.date) : [],
    datasets: [
      {
        label: "Günlük Müşteri Sayısı",
        data: data ? data.dailyCustomers.map((d) => d.count) : [],
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
    ],
  };

  const userActivitiesChartData = {
    labels: data ? data.userActivities.map((u) => u.name) : [],
    datasets: [
      {
        label: "Günlük Aktiviteler",
        data: data ? data.userActivities.map((u) => u.dailyActivities) : [],
        backgroundColor: "rgba(54, 162, 235, 0.6)",
      },
      {
        label: "Müşteri Aktiviteleri",
        data: data ? data.userActivities.map((u) => u.customerActivities) : [],
        backgroundColor: "rgba(255, 206, 86, 0.6)",
      },
    ],
  };

  const userCustomersChartData = {
    labels: data ? data.userActivities.map((u) => u.name) : [],
    datasets: [
      {
        label: "Oluşturulan Müşteri Sayısı",
        data: data ? data.userActivities.map((u) => u.customersCreated) : [],
        backgroundColor: "rgba(153, 102, 255, 0.6)",
      },
    ],
  };

  const customerCitiesChartData = {
    labels: data ? data.customerCities.map((c) => c.city) : [],
    datasets: [
      {
        data: data ? data.customerCities.map((c) => c.count) : [],
        backgroundColor: [
          "rgba(255, 99, 132, 0.6)",
          "rgba(54, 162, 235, 0.6)",
          "rgba(255, 206, 86, 0.6)",
          "rgba(75, 192, 192, 0.6)",
          "rgba(153, 102, 255, 0.6)",
        ],
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
  };

  const userActivitiesChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "y",
  };

  return (
    <Box p={5} bg={bgColor} color={textColor}>
      <VStack spacing={8} align="stretch">
        <Heading>Müşteri ve İşlem Özeti</Heading>

        <Grid
          gap={2}
          alignContent={"center"}
          templateColumns={{ base: "repeat(1, 1fr)", lg: "repeat(3, 1fr)" }}
        >
          <GridItem colSpan={1}>
            <FormControl>
              <FormLabel>Başlangıç Tarihi</FormLabel>
              <Input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </FormControl>
          </GridItem>
          <GridItem colSpan={1}>
            <FormControl>
              <FormLabel>Bitiş Tarihi</FormLabel>
              <Input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </FormControl>
          </GridItem>
          <GridItem colSpan={1}>
            <FormControl>
              <FormLabel>Kullanıcı Tipi</FormLabel>
              <Select
                value={userType}
                onChange={(e) => setUserType(e.target.value)}
              >
                <option value="admin">Admin</option>
                <option value="satis">Satış</option>
                <option value="servis">Servis</option>
              </Select>
            </FormControl>
          </GridItem>
        </Grid>
        <Button
          colorScheme="blue"
          onClick={generateReport}
          isLoading={loading}
          loadingText="Rapor Oluşturuluyor"
        >
          Rapor Oluştur
        </Button>

        {loading ? (
          <Spinner />
        ) : data ? (
          <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={8}>
            <Box bg={cardBgColor} p={5} borderRadius="md" boxShadow="md">
              <Heading size="md" mb={4}>
                Günlük Oluşturulan Müşteri Sayısı
              </Heading>
              <Box height="300px">
                <Bar data={dailyCustomersChartData} options={chartOptions} />
              </Box>
            </Box>
            <Box bg={cardBgColor} p={5} borderRadius="md" boxShadow="md">
              <Heading size="md" mb={4}>
                Kullanıcı Aktiviteleri
              </Heading>
              <Box height="300px">
                <Bar
                  data={userActivitiesChartData}
                  options={userActivitiesChartOptions}
                />
              </Box>
            </Box>
            <Box bg={cardBgColor} p={5} borderRadius="md" boxShadow="md">
              <Heading size="md" mb={4}>
                Kullanıcı Başına Oluşturulan Müşteri Sayısı
              </Heading>
              <Box height="300px">
                <Bar data={userCustomersChartData} options={chartOptions} />
              </Box>
            </Box>
            <Box bg={cardBgColor} p={5} borderRadius="md" boxShadow="md">
              <Heading size="md" mb={4}>
                Müşteri Şehirleri
              </Heading>
              <Box height="300px">
                <Pie data={customerCitiesChartData} options={chartOptions} />
              </Box>
            </Box>
          </SimpleGrid>
        ) : null}
      </VStack>
    </Box>
  );
}

export default Dashboard;

import React from "react";
import { Box, Text, Flex, Icon, GridItem } from "@chakra-ui/react";
import { InfoIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";

const MachineCard = ({ machine, onEdit, onDelete }) => {
  return (
    <GridItem
      borderWidth="1px"
      borderRadius="lg"
      bg="white"
      p={4}
      width="100%"
      colSpan={1}
      boxShadow="lg"
      transition="transform 0.2s ease-in-out"
      _hover={{ transform: "scale(1.05)" }}
      overflow="hidden"
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        flexDirection="column"
      >
        <Box
          bg="purple.100"
          color="purple.700"
          px={3}
          py={1}
          borderRadius="full"
          display={"flex"}
        >
          <Text wordBreak={"break-word"} textTransform="uppercase" fontSize="sm" textWrap="auto" textAlign={"center"} fontWeight={"700"}>
            {machine.category}
          </Text>
        </Box>
      </Flex>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        flexDirection="row"
      >
        <Text fontSize="lg" fontWeight="bold" color="gray.700">
          {machine.series}
        </Text>
        <Text fontSize="lg" color="gray.500">
          {machine.model}
        </Text>
      </Flex>
      <Box
        display="flex"
        flexDirection="row"
        gap={2}
        alignContent="center"
        width="100%"
        mb={4}
      >
        <Text
          fontSize="md"
          color="gray.600"
          width="80px"
          alignContent={"center"}
        >
          Seri No
        </Text>
        <Box
          borderColor="gray.300"
          borderRadius="md"
          borderWidth="1px"
          p={2}
          width="100%"
        >
          <Text color="gray.700">{machine.serial_number}</Text>
        </Box>
      </Box>
      <Flex justifyContent="flex-end" gap={3}>
        <Icon as={InfoIcon} color="blue.400" cursor="pointer" />
        <Icon
          as={EditIcon}
          color="yellow.400"
          cursor="pointer"
          onClick={() => onEdit(machine)}
        />
        <Icon
          as={DeleteIcon}
          color="red.400"
          cursor="pointer"
          onClick={() => onDelete(machine)}
        />
      </Flex>
    </GridItem>
  );
};

export default MachineCard;

// src/providers/ToastProvider.jsx
import React from "react";
import { ChakraProvider, useToast } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";
const ToastContext = React.createContext();

export const ToastProvider = ({ children }) => {
  const toast = useToast();

  const config = {
    initialColorMode: "light",
    useSystemColorMode: false,
  };
  const theme = extendTheme({ config });

  const showToast = ({ title, description, status, duration = 5000 }) => {
    toast({
      title,
      description,
      status,
      duration,
      isClosable: true,
      position: "bottom-right",
    });
  };

  return (
    <ToastContext.Provider value={showToast}>
      <ChakraProvider theme={theme}>{children}</ChakraProvider>
    </ToastContext.Provider>
  );
};

export const useToastContext = () => {
  return React.useContext(ToastContext);
};

import "./index.css";
import { Box, Container, Spinner, Center } from "@chakra-ui/react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
  Outlet,
} from "react-router-dom";
import HomePage from "./HomePage";
import CustomerForm from "./CustomerForm";
import React, { useContext, useEffect } from "react";
import { SessionContext } from "./SessionContext";
import Navbar from "./NavBar";
import CustomerTable from "./CustomerTable";
import CustomerDetail from "./CustomerDetail";
import Profile from "./Profile";
import LastActivities from "./LastActivities";
import Restricted from "./Restricted";
import AdminDashboard from "./AdminDashboard";
import Auth from "./Auth";
import ResetPassword from "./ResetPassword";
import DailyActivities from "./DailyActivities";
import Dashboard from "./Dashboard";

function WebLayout() {
  const location = useLocation();

  return (
    <>
      {location.pathname !== "/auth" &&
        location.pathname !== "/reset-password" && <Navbar />}
      <Container maxW="container.lg" pb={10} pt={5}>
        <Outlet />
      </Container>
    </>
  );
}

function MobileLayout() {
  const location = useLocation();
  return (
    <Box
      backgroundColor={"white"}
      minHeight="100vh"
      display="flex"
      flexDirection="column"
    >
      {location.pathname !== "/auth" &&
        location.pathname !== "/reset-password" && <Navbar />}
      <Box flex="1" overflowY="auto" pb={20}>
        {" "}
        {/* 20 is approximate height of bottom nav */}
        <Container maxW="container.lg" py={5}>
          <Outlet />
        </Container>
      </Box>
    </Box>
  );
}

export function isPWA() {
  return (
    window.matchMedia("(display-mode: standalone)").matches ||
    window.navigator.standalone
  );
}

function Layout() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return isPWA ? <MobileLayout /> : <WebLayout />;
}

function ProtectedRoute({ element, allowedPositions }) {
  const { session, userPosition, loading } = useContext(SessionContext);
  const location = useLocation();

  if (loading) {
    return (
      <Center height="100vh">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Center>
    );
  }

  if (!session) {
    return <Navigate to="/auth" replace />;
  }

  if (userPosition === "inactive" && allowedPositions.includes("inactive")) {
    return element;
  }

  if (userPosition === "inactive") {
    return <Navigate to="/restricted" replace />;
  }

  if (isPWA() && location.pathname === "/home") {
    return <Navigate to="/customers" replace />;
  }

  if (
    allowedPositions &&
    !allowedPositions.includes(userPosition) &&
    userPosition === "servis"
  ) {
    return <Navigate to="/customers" replace />;
  }

  if (allowedPositions && !allowedPositions.includes(userPosition)) {
    return <Navigate to="/home" replace />;
  }

  return element;
}

export default function App() {
  const { session, loading } = useContext(SessionContext);

  if (loading) {
    return (
      <Container
        maxW="container.lg"
        justifyContent="center"
        alignContent="center"
        height="100%"
        display="flex"
        h="100vh"
      >
        <Center height="100vh">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Center>
      </Container>
    );
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/auth"
          element={session ? <Navigate to="/home" replace /> : <Auth />}
        />
        <Route
          path="/restricted"
          element={
            <ProtectedRoute
              element={<Restricted />}
              allowedPositions={["inactive"]}
            />
          }
        />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route element={<Layout />}>
          <Route
            path="/"
            element={<Navigate to={session ? "/home" : "/auth"} />}
          />
          <Route
            path="/home"
            element={
              <ProtectedRoute
                element={<HomePage />}
                allowedPositions={["admin", "satis"]}
              />
            }
          />
          <Route
            path="/settings"
            element={<ProtectedRoute element={<Profile />} />}
          />
          <Route
            path="/add-customer"
            element={
              <ProtectedRoute
                element={<CustomerForm />}
                allowedPositions={["admin", "satis"]}
              />
            }
          />
          <Route
            path="/customers"
            element={
              <ProtectedRoute
                element={<CustomerTable />}
                allowedPositions={["admin", "servis", "satis"]}
              />
            }
          />
          <Route
            path="/last-activities"
            element={
              <ProtectedRoute
                element={<LastActivities />}
                allowedPositions={["admin", "satis"]}
              />
            }
          />
          <Route
            path="/customer/:id"
            element={
              <ProtectedRoute
                element={<CustomerDetail />}
                allowedPositions={["admin", "servis", "satis"]}
              />
            }
          />
          <Route
            path="/daily-activities"
            element={
              <ProtectedRoute
                element={<DailyActivities />}
                allowedPositions={["admin", "servis", "satis"]}
              />
            }
          />

<Route
            path="/dashboard"
            element={
              <ProtectedRoute
                element={<Dashboard />}
                allowedPositions={["admin", "servis", "satis"]}
              />
            }
          />


          <Route
            path="/admin"
            element={
              <ProtectedRoute
                element={<AdminDashboard />}
                allowedPositions={["admin"]}
              />
            }
          />
        </Route>
      </Routes>
    </Router>
  );
}

import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Heading,
  Divider,
  Select,
  Grid,
  VStack,
  Container,
  useColorModeValue,
  Textarea,
} from "@chakra-ui/react";
import { supabase } from "./supabaseClient";
import { useToastContext } from "./ToastProvider";

export const cities = [
  "İstanbul",
  "Ankara",
  "İzmir",
  "Adana",
  "Bursa",
  "Gaziantep",
  "Kayseri",
  "Konya",
  "Kocaeli",
  "Sakarya",
  "Adıyaman",
  "Afyonkarahisar",
  "Ağrı",
  "Aksaray",
  "Amasya",
  "Antalya",
  "Ardahan",
  "Artvin",
  "Aydın",
  "Balıkesir",
  "Bartın",
  "Batman",
  "Bayburt",
  "Bilecik",
  "Bingöl",
  "Bitlis",
  "Bolu",
  "Çanakkale",
  "Çankırı",
  "Çorum",
  "Denizli",
  "Diyarbakır",
  "Düzce",
  "Edirne",
  "Elazığ",
  "Erzincan",
  "Erzurum",
  "Eskişehir",
  "Giresun",
  "Gümüşhane",
  "Hakkari",
  "Hatay",
  "Iğdır",
  "Isparta",
  "Kahramanmaraş",
  "Karabük",
  "Karaman",
  "Kars",
  "Kastamonu",
  "Kırıkkale",
  "Kırklareli",
  "Kırşehir",
  "Kilis",
  "Kütahya",
  "Malatya",
  "Manisa",
  "Mardin",
  "Mersin",
  "Muğla",
  "Muş",
  "Nevşehir",
  "Niğde",
  "Ordu",
  "Osmaniye",
  "Rize",
  "Samsun",
  "Siirt",
  "Sinop",
  "Sivas",
  "Şanlıurfa",
  "Şırnak",
  "Tekirdağ",
  "Tokat",
  "Trabzon",
  "Tunceli",
  "Uşak",
  "Van",
  "Yalova",
  "Yozgat",
  "Zonguldak",
];
const CustomerForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("İstanbul");
  const [ilce, setIlce] = useState("");
  const [primaryPeople, setPrimaryPeople] = useState("");
  const [primaryPeoplePos, setPrimaryPeoplePos] = useState("");
  const [primaryPeoplePhone, setPrimaryPeoplePhone] = useState("");
  const [primaryPeopleEmail, setPrimaryPeopleEmail] = useState("");
  const [secondaryPeople, setSecondaryPeople] = useState("");
  const [secondaryPeoplePos, setSecondaryPeoplePos] = useState("");
  const [secondaryPeoplePhone, setSecondaryPeoplePhone] = useState("");
  const [secondaryPeopleEmail, setSecondaryPeopleEmail] = useState("");
  const [notes, setNotes] = useState("");
  const [customerStatus, setCustomerStatus] = useState("aktif");
  const [uid, setUid] = useState("");
  const showToast = useToastContext();

  const bgColor = useColorModeValue("gray.50", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  useEffect(() => {
    const fetchUserUid = async () => {
      const userData = await supabase.auth.getUserIdentities();
      setUid(userData.data.identities[0].id);
    };
    fetchUserUid();
  }, [uid]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Alanların boş olup olmadığını kontrol edin
    if (
      !name ||
      !phone ||
      !address ||
      !city ||
      !primaryPeople ||
      !primaryPeoplePos ||
      !primaryPeoplePhone ||
      !customerStatus
    ) {
      showToast({
        title: "Hata!",
        description: "Tüm gerekli alanları doldurun.",
        status: "error",
      });
      console.error("Hata: Tüm gerekli alanları doldurun.");
      return; // Boş alanlar varsa fonksiyondan çıkın
    }

    const userData = await supabase.auth.getUserIdentities();
    setUid(userData.data.identities[0].id);

    const { data, error } = await supabase.from("customers").insert([
      {
        name,
        email,
        phone,
        address,
        city,
        primary_people: primaryPeople,
        primary_people_pos: primaryPeoplePos,
        primary_people_phone: primaryPeoplePhone,
        primary_people_email: primaryPeopleEmail,
        secondary_people: secondaryPeople,
        secondary_people_pos: secondaryPeoplePos,
        secondary_people_phone: secondaryPeoplePhone,
        secondary_people_email: secondaryPeopleEmail,
        customer_status: customerStatus,
        created_from: uid,
        notes: notes,
        ilce,
      },
    ]);

    if (error) {
      showToast({
        title: "Hata!",
        description: "Bir hata meydana geldi.",
        status: "error",
      });
      console.error("Hata:", error);
    } else {
      showToast({
        title: "Başarılı!",
        description: "Müşteri kaydedildi",
        status: "success",
      });
      console.log("Müşteri kaydedildi:", data);
      // Başarılı kayıt işlemi sonrası form alanlarını temizleyin
      setName("");
      setEmail("");
      setPhone("");
      setAddress("");
      setCity("");
      setIlce("");
      setPrimaryPeople("");
      setPrimaryPeoplePos("");
      setPrimaryPeoplePhone("");
      setPrimaryPeopleEmail("");
      setSecondaryPeople("");
      setSecondaryPeoplePos("");
      setSecondaryPeoplePhone("");
      setSecondaryPeopleEmail("");
      setCustomerStatus("");
      setNotes("");

      // Başarılı kayıt sonrası customers path'ine yönlendirin
      window.location.href = "/customers";
    }
  };

  return (
    <Container maxW="6xl" p={2}>
      <Box
        bg={bgColor}
        borderRadius="lg"
        p={8}
        boxShadow="lg"
        borderWidth={1}
        borderColor={borderColor}
      >
        <VStack spacing={8} align="stretch">
          <Heading size="lg" textAlign="center">
            Yeni Müşteri Ekle
          </Heading>
          <form onSubmit={handleSubmit}>
            <VStack spacing={8}>
              <Box width="100%">
                <Heading size="md" mb={4}>
                  Firma Bilgileri
                </Heading>
                <Divider mb={4} />
                <Grid
                  templateColumns={{
                    base: "repeat(1, 1fr)",
                    md: "repeat(2, 1fr)",
                  }}
                  gap={6}
                >
                  <FormControl isRequired>
                    <FormLabel>Firma Adı</FormLabel>
                    <Input
                      type="text"
                      value={name}
                      required={true}
                      onChange={(e) => setName(e.target.value)}
                      focusBorderColor="yellow.400"
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>E-posta</FormLabel>
                    <Input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      focusBorderColor="yellow.400"
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>Telefon</FormLabel>
                    <Input
                      type="tel"
                      value={phone}
                      required={true}
                      onChange={(e) => setPhone(e.target.value)}
                      focusBorderColor="yellow.400"
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>Adres</FormLabel>
                    <Textarea
                      type="text"
                      value={address}
                      required={true}
                      onChange={(e) => setAddress(e.target.value)}
                      focusBorderColor="yellow.400"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Şehir</FormLabel>
                    <Select
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      focusBorderColor="yellow.400"
                    >
                      {[
                        "İstanbul",
                        "Ankara",
                        "İzmir",
                        "Adana",
                        "Bursa",
                        "Gaziantep",
                        "Kayseri",
                        "Konya",
                        "Kocaeli",
                        "Sakarya",
                      ].map((city) => (
                        <option key={city} value={city}>
                          {city}
                        </option>
                      ))}
                      {cities
                        .filter(
                          (city) =>
                            ![
                              "İstanbul",
                              "Ankara",
                              "İzmir",
                              "Adana",
                              "Bursa",
                              "Gaziantep",
                              "Kayseri",
                              "Konya",
                              "Kocaeli",
                              "Sakarya",
                            ].includes(city)
                        )
                        .map((city) => (
                          <option key={city} value={city}>
                            {city}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                  <FormControl >
                    <FormLabel>İlçe</FormLabel>
                    <Input
                      type="text"
                      value={ilce}
                      onChange={(e) => setIlce(e.target.value)}
                      focusBorderColor="yellow.400"
                    />
                  </FormControl>
                </Grid>
              </Box>
              <Box width="100%" mt={8}>
                <Heading size="md" mb={4}>
                  Birincil Yetkili
                </Heading>
                <Divider my={2} />
                <Grid
                  templateColumns={{
                    base: "repeat(1, 1fr)",
                    lg: "repeat(2, 1fr)",
                  }}
                  gap={5}
                >
                  <FormControl isRequired>
                    <FormLabel>Birincil Yetkili Kişi</FormLabel>
                    <Input
                      type="text"
                      value={primaryPeople}
                      required={true}
                      onChange={(e) => setPrimaryPeople(e.target.value)}
                      focusBorderColor="yellow.400"
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>Birincil Yetkili Pozisyonu</FormLabel>
                    <Input
                      type="text"
                      value={primaryPeoplePos}
                      required={true}
                      onChange={(e) => setPrimaryPeoplePos(e.target.value)}
                      focusBorderColor="yellow.400"
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>Birincil Yetkili Telefon</FormLabel>
                    <Input
                      type="text"
                      value={primaryPeoplePhone}
                      required={true}
                      onChange={(e) => setPrimaryPeoplePhone(e.target.value)}
                      focusBorderColor="yellow.400"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Birincil Yetkili E-posta</FormLabel>
                    <Input
                      type="email"
                      value={primaryPeopleEmail}
                      onChange={(e) => setPrimaryPeopleEmail(e.target.value)}
                      focusBorderColor="yellow.400"
                    />
                  </FormControl>
                </Grid>
              </Box>

              <Box width="100%" mt={8}>
                <Heading size="md" mb={4}>
                  İkincil Yetkili
                </Heading>
                <Divider my={2} />
                <Grid
                  templateColumns={{
                    base: "repeat(1, 1fr)",
                    lg: "repeat(2, 1fr)",
                  }}
                  gap={5}
                >
                  <FormControl>
                    <FormLabel>İkincil Yetkili Kişi</FormLabel>
                    <Input
                      type="text"
                      value={secondaryPeople}
                      onChange={(e) => setSecondaryPeople(e.target.value)}
                      focusBorderColor="yellow.400"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>İkincil Yetkili Pozisyonu</FormLabel>
                    <Input
                      type="text"
                      value={secondaryPeoplePos}
                      onChange={(e) => setSecondaryPeoplePos(e.target.value)}
                      focusBorderColor="yellow.400"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>İkincil Yetkili Telefon</FormLabel>
                    <Input
                      type="text"
                      value={secondaryPeoplePhone}
                      onChange={(e) => setSecondaryPeoplePhone(e.target.value)}
                      focusBorderColor="yellow.400"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>İkincil Yetkili E-posta</FormLabel>
                    <Input
                      type="email"
                      value={secondaryPeopleEmail}
                      onChange={(e) => setSecondaryPeopleEmail(e.target.value)}
                      focusBorderColor="yellow.400"
                    />
                  </FormControl>
                </Grid>
              </Box>
              {/* 
              <Box width="100%">
                <Heading size="md" mb={4}>
                  Görüşme Notu
                </Heading>
                <Divider mb={4} />
                <FormControl>
                  <Textarea
                    type="text"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    focusBorderColor="yellow.400"
                  />
                </FormControl>
              </Box>
              */}

              <Box width="100%">
                <Heading size="md" mb={4}>
                  Müşteri Durumu
                </Heading>
                <Divider mb={4} />
                <FormControl>
                  <FormLabel>Durum</FormLabel>
                  <Select
                    value={customerStatus}
                    onChange={(e) => setCustomerStatus(e.target.value)}
                    focusBorderColor="yellow.400"
                  >
                    <option value="aktif">Aktif</option>
                    <option value="eski">Eski</option>
                    <option value="potansiyel">Potansiyel</option>
                  </Select>
                </FormControl>
              </Box>

              <Button
                type="submit"
                backgroundColor="yellow.400"
                _hover={{ backgroundColor: "yellow.500" }}
                size="lg"
                width="full"
              >
                Müşteri Ekle
              </Button>
            </VStack>
          </form>
        </VStack>
      </Box>
    </Container>
  );
};

export default CustomerForm;

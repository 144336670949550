import React, { useEffect, useState } from "react";
import { supabase } from "./supabaseClient";
import {
  Box,
  Heading,
  Grid,
  GridItem,
  Text,
  VStack,
  useToast,
  Icon,
  Input,
  Button,
  useColorModeValue,
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Textarea,
  FormControl,
  FormLabel,
  Select,
  Card,
  CardBody,
  Stack,
  StackDivider,
  Badge,
  Avatar
} from "@chakra-ui/react";
import {
  FaUser,
  FaPhone,
  FaBriefcase,
  FaKey,
  FaSignOutAlt,
} from "react-icons/fa";

import UpdateNotes from "./UpdateNotes";

const Profile = () => {
  const [uid, setUid] = useState("");
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const toast = useToast();
  const [requestTitle, setRequestTitle] = useState("");
  const [requestDescription, setRequestDescription] = useState("");
  const [requestType, setRequestType] = useState("");
  const [requests, setRequests] = useState([]);




  useEffect(() => {
    const fetchRequests = async () => {
      if (isAdmin) {
        const { data, error } = await supabase
          .from("user_requests")
          .select("*,users_data(*)")
          .order("created_at", { ascending: false });

        if (error) {
          console.error("Error fetching requests:", error);
        } else {
          console.log(data);
          setRequests(data);
        }
      }
    };

    fetchRequests();
  }, [isAdmin]);

  

  const handleSubmitRequest = async () => {
    const { error } = await supabase.from("user_requests").insert([
      {
        user_id: uid,
        title: requestTitle,
        description: requestDescription,
        type: requestType,
        status: "open",
      },
    ]);

    if (error) {
      console.error("Error submitting request:", error);
      toast({
        title: "Hata",
        description: "Talep gönderilirken bir hata oluştu.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Başarılı",
        description: "Talebiniz başarıyla gönderildi.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setRequestTitle("");
      setRequestDescription("");
      setRequestType("");
    }
  };

  useEffect(() => {
    const fetchUserUid = async () => {
      const userData = await supabase.auth.getUser();
      setUid(userData?.data?.user?.id);
    };
    fetchUserUid();
  }, []);

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error("Oturum kapatılırken bir hata oluştu:", error);
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      if (uid) {
        const { data, error } = await supabase
          .from("users_data")
          .select("*")
          .eq("user_uid", uid)
          .single();
        if (error) {
          console.error("Error fetching user:", error);
        } else {
          setUser(data);
          setIsAdmin(data.position === "admin");
        }
      }
    };
    fetchUser();
  }, [uid]);

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      toast({
        title: "Hata",
        description: "Yeni şifreler eşleşmiyor.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const { error } = await supabase.auth.updateUser({
        password: newPassword,
      });

      if (error) throw error;

      toast({
        title: "Başarılı",
        description: "Şifreniz başarıyla güncellendi.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      toast({
        title: "Hata",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const bgColor = useColorModeValue("white", "gray.700");
  const cardBgColor = useColorModeValue("gray.50", "gray.600");

  return (
    <Flex direction="column" height="100%" overflow="hidden">
      <Box bg={bgColor} py={2} flex="0 0 auto">
        <Flex justifyContent="space-between" alignItems="center" px={2}>
          <Heading as="h1" size="lg">
            Ayarlar
          </Heading>
          <Button
            leftIcon={<FaSignOutAlt />}
            colorScheme="red"
            onClick={handleLogout}
          >
            Çıkış Yap
          </Button>
        </Flex>
      </Box>
      <Box flex="1 1 auto" overflow="auto" p={2}>
        <Tabs variant='enclosed' size={{base:"sm",lg:"md"}} >
          <TabList >
            <Tab>Profil</Tab>
            <Tab>Güncelleme Notları</Tab>
            <Tab>Talepler</Tab>
            {isAdmin && <Tab>Tüm Talepler</Tab>}
          </TabList>

          <TabPanels>
            <TabPanel px={0}>
              {user && (
                <Grid
                  templateColumns={{
                    base: "repeat(1, 1fr)",
                    md: "repeat(2, 1fr)",
                  }}
                  gap={6}
                >
                  <GridItem colSpan={1}>
                    <Box
                      bg={cardBgColor}
                      p={4}
                      borderRadius="lg"
                      boxShadow="md"
                    >
                      <VStack align="stretch" spacing={4}>
                        <Flex align="center">
                          <Icon
                            as={FaUser}
                            boxSize={5}
                            mr={2}
                            color="blue.500"
                          />
                          <Text fontWeight="bold">Ad Soyad:</Text>
                          <Text ml={2}>
                            {user.name} {user.last_name}
                          </Text>
                        </Flex>
                        <Flex align="center">
                          <Icon
                            as={FaPhone}
                            boxSize={5}
                            mr={2}
                            color="green.500"
                          />
                          <Text fontWeight="bold">Telefon:</Text>
                          <Text ml={2}>{user.number}</Text>
                        </Flex>
                        <Flex align="center">
                          <Icon
                            as={FaBriefcase}
                            boxSize={5}
                            mr={2}
                            color="purple.500"
                          />
                          <Text fontWeight="bold">Pozisyon:</Text>
                          <Text ml={2}>{user.position}</Text>
                        </Flex>
                      </VStack>
                    </Box>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Box
                      bg={cardBgColor}
                      p={6}
                      borderRadius="lg"
                      boxShadow="md"
                    >
                      <Heading as="h2" size="lg" mb={4}>
                        Şifre Değiştir
                      </Heading>
                      <VStack spacing={4} align="stretch">
                        <Flex align="center">
                          <Icon as={FaKey} boxSize={5} mr={2} color="red.500" />
                          <Input
                            type="password"
                            placeholder="Mevcut Şifre"
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                          />
                        </Flex>
                        <Flex align="center">
                          <Icon
                            as={FaKey}
                            boxSize={5}
                            mr={2}
                            color="orange.500"
                          />
                          <Input
                            type="password"
                            placeholder="Yeni Şifre"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                          />
                        </Flex>
                        <Flex align="center">
                          <Icon
                            as={FaKey}
                            boxSize={5}
                            mr={2}
                            color="yellow.500"
                          />
                          <Input
                            type="password"
                            placeholder="Yeni Şifre (Tekrar)"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                        </Flex>
                        <Button
                          colorScheme="blue"
                          onClick={handleChangePassword}
                        >
                          Şifreyi Değiştir
                        </Button>
                      </VStack>
                    </Box>
                  </GridItem>
                </Grid>
              )}
            </TabPanel>

            <TabPanel px={0}>
            <UpdateNotes/>
            </TabPanel>

            <TabPanel px={0}>
              <Box bg={cardBgColor} p={4} borderRadius="lg" boxShadow="md">
                <Heading as="h2" size="lg" mb={4}>
                  Yeni Talep Oluştur
                </Heading>
                <VStack spacing={4} align="stretch">
                  <FormControl>
                    <FormLabel>Talep Başlığı</FormLabel>
                    <Input
                      value={requestTitle}
                      onChange={(e) => setRequestTitle(e.target.value)}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Talep Açıklaması</FormLabel>
                    <Textarea
                      value={requestDescription}
                      onChange={(e) => setRequestDescription(e.target.value)}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Talep Tipi</FormLabel>
                    <Select
                      value={requestType}
                      onChange={(e) => setRequestType(e.target.value)}
                    >
                      <option value="">Seçiniz</option>
                      <option value="bug">Hata Bildirimi</option>
                      <option value="feature">Özellik İsteği</option>
                      <option value="other">Diğer</option>
                    </Select>
                  </FormControl>
                  <Button colorScheme="blue" onClick={handleSubmitRequest}>
                    Talebi Gönder
                  </Button>
                </VStack>
              </Box>
            </TabPanel>

            {isAdmin && (
              <TabPanel px={0}>
                <Box bg={cardBgColor} p={4} borderRadius="lg" boxShadow="md">
                  <Heading as="h2" size="lg" mb={4}>
                    Tüm Talepler
                  </Heading>
                  <Stack divider={<StackDivider />} spacing="4">
                    {requests.map((request) => (
                      <Card key={request.id}>
                        <CardBody>
                          <Flex
                            justifyContent="space-between"
                            alignItems="center"
                            mb={2}
                          >
                            <Heading size="md">{request.title}</Heading>
                            <Flex alignItems="center">
                              <Avatar
                                size="sm"
                                name={request.users_data.name}
                                mr={2}
                              />
                              <Text fontSize="sm">
                                {request.users_data.name}
                              </Text>
                            </Flex>
                          </Flex>
                          <Text fontSize="sm" mb={2}>
                            {request.description}
                          </Text>
                          <Flex
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Badge
                              colorScheme={
                                request.status === "open" ? "green" : "red"
                              }
                            >
                              {request.status}
                            </Badge>
                            <Text fontSize="sm">{request.type}</Text>
                          </Flex>
                        </CardBody>
                      </Card>
                    ))}
                  </Stack>
                </Box>
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>
      </Box>
    </Flex>
  );
};

export default Profile;

import React, { useState, useEffect, useCallback } from "react";
import { supabase } from "./supabaseClient";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { Spinner, Center, VStack } from "@chakra-ui/react";
import {
  Text,
  Box,
  Heading,
  Divider,
  SimpleGrid,
  LinkBox,
  LinkOverlay,
  Avatar,
  Input,
  Select,
  Flex,
  Badge,
  useBreakpointValue,
  Button,
  Collapse,
  useDisclosure,
  Icon,
  ButtonGroup,
  IconButton,
  Tag,
  TagLabel,
  TagCloseButton,
} from "@chakra-ui/react";
import {
  SearchIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  AddIcon,
} from "@chakra-ui/icons";
import {
  FaFilter,
  FaSortAlphaDown,
  FaUserCircle,
  FaMapPin,
  FaComment,
} from "react-icons/fa";
import { isPWA } from "./App";

const CustomerTable = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [customers, setCustomers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentUserId, setUid] = useState("");
  const [isSales, setIsSales] = useState("");
  const [unreadComments, setUnreadComments] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const itemsPerPage = 10;

  const [filters, setFilters] = useState({
    searchTerms: [],
    statuses: [],
    cities: [],
    users: [],
    sortBy: "last_activity",
  });

  const [tempFilters, setTempFilters] = useState({
    searchTerm: "",
    status: "",
    city: "",
    user: "",
    sortBy: "last_activity",
  });

  const columns = useBreakpointValue({ base: 1, md: 2 });
  const { isOpen, onToggle } = useDisclosure();

  useEffect(() => {
    const fetchUserUid = async () => {
      const userData = await supabase.auth.getUserIdentities();
      console.log(userData);
      setUid(userData.data.identities[0].id);
      const { data: users_data, error } = await supabase
        .from("users_data")
        .select("*")
        .eq("user_uid", userData.data.identities[0].id)
        .single();
      if (error) {
        console.error("Error fetching user:", error);
      } else {
        setIsSales(users_data.position === "satis");
      }
    };

    fetchUserUid();
  }, [currentUserId]);

  const fetchCustomers = useCallback(
    async (currentFilters, page) => {
      setIsLoading(true);
      if (isSales === "") {
        return; // Fonksiyondan çık
      }

      let data, error;
      if (isSales) {
        ({ data, error } = await supabase.rpc("get_customers", {
          search_terms: currentFilters.searchTerms,
          statuses: currentFilters.statuses,
          cities: currentFilters.cities,
          users: currentFilters.users,
          sort_by: currentFilters.sortBy,
          items_per_page: itemsPerPage,
          page_number: page,
          created_from: currentUserId,
        }));
      } else {
        ({ data, error } = await supabase.rpc("get_filtered_customers", {
          search_terms: currentFilters.searchTerms,
          statuses: currentFilters.statuses,
          cities: currentFilters.cities,
          users: currentFilters.users,
          sort_by: currentFilters.sortBy,
          page_number: page,
          items_per_page: itemsPerPage,
        }));
      }

      if (error) {
        console.error("Error fetching customers:", error);

        return;
      }

      setIsLoading(false);

      if (data && data.length > 0) {
        const totalCount = data[0].total_count;
        setCustomers(data);
        setTotalPages(Math.ceil(totalCount / itemsPerPage));

        // Fetch unread comment counts
        const customerIds = data.map((customer) => customer.id);
        const { data: commentData, error: commentError } = await supabase
          .from("customer_activities")
          .select(
            `
            id,
            customer_id,
            activity_comments!left(
              id,
              user_id,
              comment_reads!left(user_id)
            )
          `
          )
          .in("customer_id", customerIds);

        if (commentError) {
          console.error("Error fetching unread comments:", commentError);
        } else {
          const unreadCounts = commentData.reduce((acc, activity) => {
            const unreadCount = activity.activity_comments.filter(
              (comment) =>
                comment.user_id !== currentUserId &&
                !comment.comment_reads.some(
                  (read) => read.user_id === currentUserId
                )
            ).length;

            if (unreadCount > 0) {
              acc[activity.customer_id] =
                (acc[activity.customer_id] || 0) + unreadCount;
            }

            return acc;
          }, {});

          setUnreadComments(unreadCounts);
        }
      } else {
        setCustomers([]);
        setTotalPages(0);
        setUnreadComments({});
      }
    },
    [itemsPerPage, currentUserId, isSales]
  );

  useEffect(() => {
    fetchCustomers(filters, currentPage);
  }, [fetchCustomers, filters, currentPage]);

  useEffect(() => {
    const commentSubscription = supabase
      .channel("comment_changes")
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "activity_comments",
        },
        () => {
          fetchCustomers(filters, currentPage);
        }
      )
      .subscribe();

    const readSubscription = supabase
      .channel("comment_read_changes")
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "comment_reads",
        },
        () => {
          fetchCustomers(filters, currentPage);
        }
      )
      .subscribe();

    return () => {
      commentSubscription.unsubscribe();
      readSubscription.unsubscribe();
    };
  }, [fetchCustomers, filters, currentPage]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const newFilters = {
      searchTerms: params.getAll("search").map(decodeURIComponent),
      statuses: params.getAll("status").map(decodeURIComponent),
      cities: params.getAll("city").map(decodeURIComponent),
      users: params.getAll("user").map(decodeURIComponent),
      sortBy: decodeURIComponent(params.get("sort") || "last_activity"),
    };
    setFilters(newFilters);
    setTempFilters({
      searchTerm: "",
      status: "",
      city: "",
      user: "",
      sortBy: newFilters.sortBy,
    });
    const pageFromUrl = parseInt(params.get("page") || "1", 10);
    setCurrentPage(pageFromUrl);

    fetchCustomers(newFilters, pageFromUrl);
  }, [location, fetchCustomers]);

  const removeFilter = (filterType, value) => {
    const newFilters = { ...filters };
    if (filterType === "sortBy") {
      newFilters.sortBy = "last_activity"; // Reset to default sorting
    } else {
      newFilters[filterType] = newFilters[filterType].filter(
        (item) => item !== value
      );
    }
    setFilters(newFilters);
    setCurrentPage(1);
    fetchCustomers(newFilters, 1);
    updateURL(newFilters, 1);
  };
  const applyFilters = (filtersToApply = tempFilters) => {
    const newFilters = {
      ...filters,
      searchTerms: filtersToApply.searchTerm
        ? [...filters.searchTerms, filtersToApply.searchTerm]
        : filters.searchTerms,
      statuses: filtersToApply.status
        ? [...filters.statuses, filtersToApply.status]
        : filters.statuses,
      cities: filtersToApply.city
        ? [...filters.cities, filtersToApply.city]
        : filters.cities,
      users: filtersToApply.user
        ? [...filters.users, filtersToApply.user]
        : filters.users,
      sortBy: filtersToApply.sortBy || filters.sortBy,
    };
    setFilters(newFilters);
    setCurrentPage(1);
    fetchCustomers(newFilters, 1);
    updateURL(newFilters, 1);

    // Clear temp filters
    setTempFilters({
      searchTerm: "",
      status: "",
      city: "",
      user: "",
      sortBy: newFilters.sortBy,
    });
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    fetchCustomers(filters, newPage);
    updateURL(filters, newPage);
  };

  const handleTempFilterChange = (filterType, value) => {
    setTempFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: value,
    }));
  };

  const updateURL = (currentFilters, page) => {
    const params = new URLSearchParams();
    currentFilters.searchTerms.forEach((term) =>
      params.append("search", encodeURIComponent(term))
    );
    currentFilters.statuses.forEach((status) =>
      params.append("status", encodeURIComponent(status))
    );
    currentFilters.cities.forEach((city) =>
      params.append("city", encodeURIComponent(city))
    );
    currentFilters.users.forEach((user) =>
      params.append("user", encodeURIComponent(user))
    );
    if (currentFilters.sortBy !== "last_activity")
      params.append("sort", encodeURIComponent(currentFilters.sortBy));
    params.append("page", page.toString());

    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  };

  const getTimeDiffString = (lastActivityDate) => {
    if (!lastActivityDate) return "Hiç işlem yok";

    const now = new Date();
    const utcDate = new Date(lastActivityDate);
    const localDate = new Date(
      utcDate.getTime() - utcDate.getTimezoneOffset() * 60000
    );
    const diff = now.getTime() - localDate.getTime();
    const diffMinutes = Math.floor(diff / (1000 * 60));
    const diffHours = Math.floor(diffMinutes / 60);
    const diffDays = Math.floor(diffHours / 24);

    if (diffDays > 0) {
      return `${diffDays} gün önce`;
    } else if (diffHours > 0) {
      return `${diffHours} saat önce`;
    } else {
      return `${diffMinutes} dakika önce`;
    }
  };

  useEffect(() => {
    const commentSubscription = supabase
      .channel("comment_changes")
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "activity_comments",
        },
        () => {
          fetchCustomers(filters, currentPage);
        }
      )
      .subscribe();

    const readSubscription = supabase
      .channel("comment_read_changes")
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "comment_reads",
        },
        () => {
          fetchCustomers(filters, currentPage);
        }
      )
      .subscribe();

    return () => {
      commentSubscription.unsubscribe();
      readSubscription.unsubscribe();
    };
  }, [fetchCustomers, filters, currentPage]);

  return (
    <Box mb={isPWA() ? 0 : 0} width="100%" mx="auto">
      <Flex justify="space-between" align="center" mb={4}>
        <Heading size="lg">Müşteriler</Heading>
        <Button leftIcon={<Icon as={FaFilter} />} onClick={onToggle}>
          Filtreler
        </Button>
      </Flex>
      <Divider my={5} />

      {/* Active Filters */}
      <Flex wrap="wrap" gap={2} mb={4}>
        {filters.searchTerms.map((term, index) => (
          <Tag
            key={`search-${index}`}
            borderRadius="full"
            variant="solid"
            colorScheme="blue"
          >
            <TagLabel>Arama: {term}</TagLabel>
            <TagCloseButton onClick={() => removeFilter("searchTerms", term)} />
          </Tag>
        ))}
        {filters.statuses.map((status, index) => (
          <Tag
            key={`status-${index}`}
            borderRadius="full"
            variant="solid"
            colorScheme="green"
          >
            <TagLabel>Durum: {status}</TagLabel>
            <TagCloseButton onClick={() => removeFilter("statuses", status)} />
          </Tag>
        ))}
        {filters.cities.map((city, index) => (
          <Tag
            key={`city-${index}`}
            borderRadius="full"
            variant="solid"
            colorScheme="orange"
          >
            <TagLabel>Şehir: {city}</TagLabel>
            <TagCloseButton onClick={() => removeFilter("cities", city)} />
          </Tag>
        ))}
        {filters.users.map((user, index) => (
          <Tag
            key={`user-${index}`}
            borderRadius="full"
            variant="solid"
            colorScheme="purple"
          >
            <TagLabel>Kullanıcı: {user}</TagLabel>
            <TagCloseButton onClick={() => removeFilter("users", user)} />
          </Tag>
        ))}
        {filters.sortBy !== "last_activity" && (
          <Tag borderRadius="full" variant="solid" colorScheme="cyan">
            <TagLabel>
              Sıralama:{" "}
              {filters.sortBy === "created_at"
                ? "Müşteri Oluşturulma Tarihi"
                : filters.sortBy === "activity_count"
                ? "İşlem Sayısı"
                : filters.sortBy}
            </TagLabel>
            <TagCloseButton onClick={() => removeFilter("sortBy")} />
          </Tag>
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <SimpleGrid columns={columns} spacing={4} mb={6}>
          <Input
            placeholder="Müşteri ismine göre ara"
            value={tempFilters.searchTerm}
            onChange={(e) =>
              handleTempFilterChange("searchTerm", e.target.value)
            }
            leftIcon={<SearchIcon />}
          />
          <Select
            placeholder="Duruma göre filtrele"
            value={tempFilters.status}
            onChange={(e) => handleTempFilterChange("status", e.target.value)}
          >
            <option value="aktif">Aktif</option>
            <option value="eski">Eski</option>
            <option value="potansiyel">Potansiyel</option>
          </Select>
          <Input
            placeholder="Şehire göre filtrele"
            value={tempFilters.city}
            onChange={(e) => handleTempFilterChange("city", e.target.value)}
            leftIcon={<Icon as={FaMapPin} />}
          />
          <Input
            placeholder="Oluşturan kişiye göre filtrele"
            value={tempFilters.user}
            onChange={(e) => handleTempFilterChange("user", e.target.value)}
            leftIcon={<Icon as={FaUserCircle} />}
          />
          <Select
            placeholder="Sırala"
            value={tempFilters.sortBy}
            onChange={(e) => handleTempFilterChange("sortBy", e.target.value)}
            icon={<Icon as={FaSortAlphaDown} />}
          >
            <option value="created_at">Oluşturulma Tarihine Göre</option>
            <option value="last_activity">Son İşlem Tarihine Göre</option>
            <option value="activity_count">İşlem Sayısına Göre</option>
          </Select>
          <Button
            onClick={() => applyFilters()}
            colorScheme="blue"
            leftIcon={<AddIcon />}
          >
            Filtre Ekle
          </Button>
        </SimpleGrid>
      </Collapse>

      {isLoading ? (
        <Center w={"100%"}>
          <VStack spacing={8} align="stretch" width="100%" alignItems={"center"} justifySelf={"center"}>
            <Spinner size="xl" color="blue.500" thickness="4px" speed="0.65s" />
          </VStack>
        </Center>
      ) : (
        <SimpleGrid columns={columns} spacing={6}>
          {customers.map((customer) => (
            <LinkBox
              key={customer.id}
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              boxShadow="md"
              transition="all 0.3s"
              _hover={{ transform: "translateY(-5px)", boxShadow: "lg" }}
            >
              <LinkOverlay as={RouterLink} to={`/customer/${customer.id}`}>
                <Box p={4}>
                  <Flex justify="space-between" align="center" mb={3}>
                    <Heading size="md" noOfLines={1}>
                      {customer.name}
                    </Heading>
                    <Box gap={2}>
                      {unreadComments[customer.id] > 0 && (
                        <Badge colorScheme="red" mr={2}>
                          <Icon as={FaComment} mr={1} />
                          {unreadComments[customer.id]}
                        </Badge>
                      )}
                      <Badge
                        colorScheme={
                          customer.customer_status === "aktif"
                            ? "green"
                            : customer.customer_status === "eski"
                            ? "red"
                            : "yellow"
                        }
                      >
                        {customer.customer_status}
                      </Badge>
                    </Box>
                  </Flex>
                  <Text fontSize="sm" color="gray.600" mb={3}>
                    <Icon as={FaMapPin} mr={2} />{" "}
                    {customer.city || "Belirtilmemiş"}
                    {customer.ilce && (
                      <>
                        {" / "} {customer.ilce}
                      </>
                    )}
                  </Text>
                  <Flex justify="space-between" align="center">
                    <Flex align="center">
                      <Avatar
                        name={`${customer.user_name || ""} ${
                          customer.user_last_name || ""
                        }`}
                        size="sm"
                        mr={2}
                      />
                      <Text fontSize="sm" fontWeight="medium">
                        {customer.user_name && customer.user_last_name
                          ? `${customer.user_name} ${customer.user_last_name}`
                          : "Belirtilmemiş"}
                      </Text>
                    </Flex>
                    <Flex direction="column" align="flex-end">
                      <Text fontSize="sm" fontWeight="bold">
                        {customer.activity_count} İşlem
                      </Text>
                      <Text fontSize="xs" color="gray.500">
                        {getTimeDiffString(customer.last_activity_time)}
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
              </LinkOverlay>
            </LinkBox>
          ))}
        </SimpleGrid>
      )}

      <Flex justifyContent="center" my={4}>
        <ButtonGroup isAttached variant="outline">
          <IconButton
            icon={<ChevronLeftIcon />}
            onClick={() => handlePageChange(currentPage - 1)}
            isDisabled={currentPage === 1}
          />
          <Button isDisabled>{currentPage}</Button>
          <IconButton
            icon={<ChevronRightIcon />}
            onClick={() => handlePageChange(currentPage + 1)}
            isDisabled={currentPage === totalPages}
          />
        </ButtonGroup>
      </Flex>
    </Box>
  );
};

export default CustomerTable;

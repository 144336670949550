import React, { useState, useEffect, useRef } from "react";
import { supabase } from "./supabaseClient";
import {
  Box,
  Text,
  Flex,
  Button,
  Link,
  Icon,
  Tooltip,
  Divider,
  Avatar,
  ButtonGroup,
  IconButton,
} from "@chakra-ui/react";
import {
  AttachmentIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import { FaGlobe, FaLock, FaBuilding } from "react-icons/fa";
import { GiAutoRepair } from "react-icons/gi";
import { FaHandHoldingDollar } from "react-icons/fa6";
import { RiAdminLine } from "react-icons/ri";

const LastActivities = () => {
  const [activities, setActivities] = useState([]);
  const [signedUrls, setSignedUrls] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;
  const topRef = useRef(null);

  useEffect(() => {
    const handleRealtimeChanges = async (payload) => {
      const { eventType, new: newRecord, old: oldRecord } = payload;

      switch (eventType) {
        case "INSERT":
          if (currentPage === 1) {
            const { data, error } = await supabase
              .from("customer_activities")
              .select(`*, customer_attaches(*), customers(*), users_data(*)`)
              .eq("id", newRecord.id)
              .single();

            if (error) {
              console.error("Error fetching new activity:", error);
            } else {
              setActivities((prevActivities) => {
                const updatedActivities = [
                  data,
                  ...prevActivities.slice(0, itemsPerPage - 1),
                ];
                getSignedUrls(updatedActivities);
                return updatedActivities;
              });
            }
          }
          setTotalPages((prevTotalPages) =>
            Math.ceil((prevTotalPages * itemsPerPage + 1) / itemsPerPage)
          );
          break;
        case "UPDATE":
          setActivities((prevActivities) =>
            prevActivities.map((activity) =>
              activity.id === newRecord.id
                ? { ...activity, ...newRecord }
                : activity
            )
          );
          break;
        case "DELETE":
          if (currentPage === 1) {
            setActivities((prevActivities) =>
              prevActivities.filter((activity) => activity.id !== oldRecord.id)
            );
          }
          setTotalPages((prevTotalPages) =>
            Math.max(
              1,
              Math.ceil((prevTotalPages * itemsPerPage - 1) / itemsPerPage)
            )
          );
          break;
        default:
          console.log("Unhandled event type:", eventType);
      }
    };
    const fetchLastActivities = async (page = 1) => {
      const from = (page - 1) * itemsPerPage;
      const to = from + itemsPerPage - 1;

      const { data, error, count } = await supabase
        .from("customer_activities")
        .select(
          `*,
            customer_attaches(*),
            customers(*),
            users_data(*)`,
          { count: "exact" }
        )
        .order("created_at", { ascending: false })
        .range(from, to);

      if (error) {
        console.error("Error fetching last activities:", error);
      } else {
        setActivities(data);
        setTotalPages(Math.ceil(count / itemsPerPage));
        getSignedUrls(data);
      }
    };

    fetchLastActivities(currentPage);
    const subscription = supabase
      .channel("last_activities_changes")
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "customer_activities",
        },
        (payload) => {
          handleRealtimeChanges(payload);
        }
      )
      .subscribe();

    return () => {
      subscription.unsubscribe();
    };

   
  }, [currentPage]);

  const getSignedUrls = async (activitiesData) => {
    const paths = activitiesData.flatMap(
      (activity) =>
        activity.customer_attaches?.map((attach) => attach.file_url) || []
    );

    if (paths.length === 0) return;

    try {
      const { data, error } = await supabase.storage
        .from("sunton-crm-bucket")
        .createSignedUrls(paths, 300);

      if (error) {
        console.error("Error getting signed URLs:", error);
        return;
      }

      const urls = {};
      data.forEach((urlData) => {
        urls[urlData.path] = urlData.signedUrl;
      });
      setSignedUrls(urls);
    } catch (error) {
      console.error("Error getting signed URLs:", error);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
      window.scrollTo(0, 0);
    }
  };
  const getVisibilityIcon = (visibility) => {
    switch (visibility) {
      case "all":
        return <Icon as={FaGlobe} />;
      case "admin":
        return <Icon as={FaLock} />;
      default:
        return null;
    }
  };

  const getIcon = (position) => {
    if (position) {
      switch (position.toLowerCase()) {
        case "servis":
          return GiAutoRepair;
        case "satis":
          return FaHandHoldingDollar;
        case "admin":
          return RiAdminLine;
        default:
          return null;
      }
    }
  };

  return (
    <>
      <div ref={topRef} style={{ height: "1px", marginTop: "-1px" }} />
      {activities.length > 0 && (
        <Box>
          {activities.map((activity) => (
            <Box
              key={activity.id}
              bg="gray.50"
              p={4}
              mb={4}
              borderRadius="md"
              boxShadow="md"
            >
              <Flex alignItems="center" mb={3}>
                <Icon as={FaBuilding} mr={2} color="blue.500" />
                <Text fontWeight="bold" fontSize="lg" color="blue.600" flex={1}>
                  {activity.customers?.name}
                </Text>
                <Text fontSize="sm" color="gray.500">
                  {(() => {
                    const utcDate = new Date(activity.created_at);
                    const localDate = new Date(
                      utcDate.getTime() - utcDate.getTimezoneOffset() * 60000
                    );

                    return `${localDate.toLocaleDateString("tr-TR", {
                      day: "2-digit",
                      month: "short",
                      year: "numeric",
                    })} ${localDate.toLocaleTimeString("tr-TR", {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false,
                    })}`;
                  })()}
                </Text>
              </Flex>

              <Flex mb={3}>
                <Text fontWeight="600" width="80px" alignSelf="center">
                  Başlık
                </Text>
                <Box
                  flex={1}
                  border="1px solid"
                  borderColor="gray.200"
                  borderRadius="md"
                  p={2}
                >
                  <Text>{activity.title}</Text>
                </Box>
                <Flex ml={2} alignItems="center" gap={2}>
                  <Box
                    minW="35px"
                    minH="35px"
                    backgroundColor="yellow.400"
                    alignItems="center"
                    justifyContent="center"
                    display="flex"
                    borderRadius="md"
                  >
                    <Icon as={getIcon(activity.users_data.position)} />
                  </Box>
                  <Tooltip
                    label={
                      activity.visibility === "all"
                        ? "Herkes görebilir"
                        : "Sadece yetkililer görebilir"
                    }
                  >
                    <Box
                      minW="35px"
                      minH="35px"
                      backgroundColor="yellow.400"
                      alignItems="center"
                      justifyContent="center"
                      display="flex"
                      borderRadius="md"
                    >
                      {getVisibilityIcon(activity.visibility)}
                    </Box>
                  </Tooltip>
                </Flex>
              </Flex>

              <Flex mb={3}>
                <Text fontWeight="600" width="80px" alignSelf="center">
                  İşlem
                </Text>
                <Box
                  flex={1}
                  border="1px solid"
                  borderColor="gray.200"
                  borderRadius="md"
                  p={2}
                >
                  <Text
                    whiteSpace="pre-wrap"
                    wordBreak="break-word"
                    width="100%"
                  >
                    {activity.activity}
                  </Text>
                </Box>
              </Flex>

              {activity.customer_attaches &&
                activity.customer_attaches.length > 0 && (
                  <Flex mb={3}>
                    <Text fontWeight="600" width="80px" alignSelf="center">
                      Ekler
                    </Text>
                    <Flex flex={1} flexWrap="wrap" gap={2}>
                      {activity.customer_attaches.map((attach, index) => (
                        <Tooltip
                          key={index}
                          label={attach.file_url}
                          fontSize="md"
                        >
                          <Button
                            leftIcon={<AttachmentIcon />}
                            size="sm"
                            variant="outline"
                          >
                            <Link
                              href={signedUrls[attach.file_url] || "#"}
                              isExternal
                            >
                              Ek {index + 1}
                            </Link>
                          </Button>
                        </Tooltip>
                      ))}
                    </Flex>
                  </Flex>
                )}

              <Divider my={2} />
              <Flex justifyContent="flex-end" alignItems="center">
                <Avatar
                  name={
                    activity.users_data.name +
                    " " +
                    activity.users_data.last_name
                  }
                  size="sm"
                  mr={2}
                />
                <Text fontSize="sm">
                  {activity.users_data.name +
                    " " +
                    activity.users_data.last_name}
                </Text>
              </Flex>
            </Box>
          ))}
          <Box my={4} display="flex" justifyContent="center">
            <ButtonGroup isAttached variant="outline">
              <IconButton
                icon={<ChevronLeftIcon />}
                onClick={() => handlePageChange(currentPage - 1)}
                isDisabled={currentPage === 1}
              />
              <Button isDisabled>{currentPage}</Button>
              <IconButton
                icon={<ChevronRightIcon />}
                onClick={() => handlePageChange(currentPage + 1)}
                isDisabled={currentPage === totalPages}
              />
            </ButtonGroup>
          </Box>
        </Box>
      )}
    </>
  );
};

export default LastActivities;

import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { supabase } from "./supabaseClient";
import {
  Container,
  Heading,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  SimpleGrid,
  VStack,
  HStack,
  Select,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import MachineCard from "./MachineCard";

const machineCategories = [
  {
    name: "Metal Plaka Lazer Kesim Makinası",
    series: [
      "C Serisi",
      "A Serisi",
      "P Serisi",
      "İ Serisi",
      "Dream Serisi",
      "R Serisi",
    ],
  },
  {
    name: "Metal Boru Lazer Kesim Makinası",
    series: ["K Serisi", "T Serisi", "M Serisi"],
  },
  {
    name: "Metal Plaka & Boru Lazer Kesim Makinası",
    series: ["AT Serisi", "CT Serisi"],
  },
];

const MachineSelector = ({ onSelect, value }) => {
  const handleCategoryChange = (e) => {
    onSelect(e.target.value);
  };

  return (
    <FormControl>
      <FormLabel>Kategori</FormLabel>
      <Select value={value} onChange={handleCategoryChange}>
        <option value="">Seçiniz</option>
        {machineCategories.map((category, index) => (
          <option key={index} value={category.name}>
            {category.name}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};

const CustomerMachines = () => {
  const { id } = useParams();
  const [machines, setMachines] = useState([]);
  const [selectedMachine, setSelectedMachine] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isAlertOpen,
    onOpen: onAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure();
  const cancelRef = React.useRef();

  const [machineForm, setMachineForm] = useState({
    category: "",
    series: "",
    model: "",
    serial_number: "",
    additional_info: "",
  });

  const fetchMachines = useCallback(async () => {
    const { data, error } = await supabase
      .from("machines")
      .select("*")
      .eq("customer_id", id);
    if (error) {
      console.error("Error fetching machines:", error);
    } else {
      setMachines(data);
    }
  }, [id]);

  useEffect(() => {
    fetchMachines();
  }, [fetchMachines]);

  const handleOpenModal = (machine = null) => {
    if (machine) {
      setMachineForm(machine);
      setSelectedMachine(machine);
    } else {
      setMachineForm({
        category: "",
        series: "",
        model: "",
        serial_number: "",
        additional_info: "",
      });
      setSelectedMachine(null);
    }
    onOpen();
  };
  const handleSubmit = async () => {
    if (!machineForm.category) {
      alert("Lütfen bir kategori seçin.");
      return;
    }

    if (selectedMachine) {
      const { error } = await supabase
        .from("machines")
        .update(machineForm)
        .eq("id", selectedMachine.id);
      if (error) {
        console.error("Error updating machine:", error);
      } else {
        fetchMachines();
      }
    } else {
      const { error } = await supabase.from("machines").insert({
        ...machineForm,
        customer_id: id,
      });
      if (error) {
        console.error("Error adding machine:", error);
      } else {
        fetchMachines();
      }
    }
    onClose();
  };

  const handleDelete = async () => {
    if (selectedMachine) {
      const { error } = await supabase
        .from("machines")
        .delete()
        .eq("id", selectedMachine.id);
      if (error) {
        console.error("Error deleting machine:", error);
      } else {
        fetchMachines();
      }
    }
    onAlertClose();
    setSelectedMachine(null);
  };

  return (
    <Container maxW="container.xl" py={8}>
      <VStack spacing={8} align="stretch">
        <HStack justifyContent="space-between">
          <Heading as="h1" size="md">
            Müşteri Makinaları
          </Heading>
          <Button
            leftIcon={<AddIcon />}
            colorScheme="blue"
            onClick={() => handleOpenModal()}
          >
            Yeni Makina Ekle
          </Button>
        </HStack>

        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} spacing={6}>
          {machines.map((machine) => (
            <MachineCard
              key={machine.id}
              machine={machine}
              onEdit={handleOpenModal}
              onDelete={() => {
                setSelectedMachine(machine);
                onAlertOpen();
              }}
            />
          ))}
        </SimpleGrid>
      </VStack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {selectedMachine ? "Makina Düzenle" : "Yeni Makina Ekle"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <MachineSelector
              onSelect={(category) =>
                setMachineForm({ ...machineForm, category })
              }
              value={machineForm.category}
            />
            <FormControl mt={4}>
              <FormLabel>Seri</FormLabel>
              <Input
                value={machineForm.series}
                onChange={(e) =>
                  setMachineForm({ ...machineForm, series: e.target.value })
                }
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Model</FormLabel>
              <Input
                value={machineForm.model}
                onChange={(e) =>
                  setMachineForm({ ...machineForm, model: e.target.value })
                }
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Seri No</FormLabel>
              <Input
                value={machineForm.serial_number}
                onChange={(e) =>
                  setMachineForm({
                    ...machineForm,
                    serial_number: e.target.value,
                  })
                }
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Ek Bilgi</FormLabel>
              <Textarea
                value={machineForm.additional_info}
                onChange={(e) =>
                  setMachineForm({
                    ...machineForm,
                    additional_info: e.target.value,
                  })
                }
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
              {selectedMachine ? "Güncelle" : "Ekle"}
            </Button>
            <Button onClick={onClose}>İptal</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onAlertClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Makina Sil
            </AlertDialogHeader>

            <AlertDialogBody>
              Bu makinayı silmek istediğinizden emin misiniz? Bu işlem geri
              alınamaz.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onAlertClose}>
                İptal
              </Button>
              <Button colorScheme="red" onClick={handleDelete} ml={3}>
                Sil
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Container>
  );
};

export default CustomerMachines;

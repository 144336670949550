import { Center, Text, Grid, Box, Button, Image } from "@chakra-ui/react";
import React from "react";
import { supabase } from "./supabaseClient";

export default function Restricted() {
  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error("Oturum kapatılırken bir hata oluştu:", error);
    }
  };
  return (
    <Center width={"100vw"} h={"100vh"}>
      <Grid justifyItems={"center"} alignItems={"center"} w={"fit-content"}>
        <Box
          backgroundColor={"gray.500"}
          justifyItems={"center"}
          alignContent={"center"}
          w={"fit-content"}
          p={2}
          borderRadius={10}
        >
          <Image src={"/logo-1.svg"} />
        </Box>
        <br></br>
        <Box>
          <Text color={"black"}>
            Hesabınız Kısıtlanmıştır. Admin ile İletişime geçiniz.
          </Text>
        </Box>
        <Button colorScheme="red" onClick={handleLogout} mt={5}>
          Çıkış Yap
        </Button>
      </Grid>
    </Center>
  );
}

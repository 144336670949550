import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Heading,
  Text,
  Grid,
  GridItem,
  Textarea,
  FormControl,
  FormLabel,
  Button,
  Input,
  Link,
  Progress,
  Tooltip,
  Divider,
  Avatar,
  Flex,
  useColorModeValue,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Select,
  useToast,
  IconButton,
} from "@chakra-ui/react";
import { AttachmentIcon, DeleteIcon } from "@chakra-ui/icons";
import { FaUpload } from "react-icons/fa";
import { supabase } from "./supabaseClient";

const DailyActivities = () => {
  const [uid, setUid] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [newActivity, setNewActivity] = useState("");
  const [newTitle, setNewTitle] = useState("");
  const [activities, setActivities] = useState([]);
  const [uploadedFileUrl, setUploadedFileUrl] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [signedUrls, setSignedUrls] = useState({});

  const pinnedBgColor = useColorModeValue("purple.50", "purple.700");
  const unpinnedBgColor = useColorModeValue("gray.100", "gray.700");

  useEffect(() => {
    fetchUserUid();
  }, []);

  useEffect(() => {
    if (isAdmin) {
      fetchUsers();
    }
  }, [isAdmin]);

  const fetchUserUid = async () => {
    const { data } = await supabase.auth.getUser();
    if (data?.user) {
      setUid(data.user.id);
      setSelectedUser(data.user.id);
    }
  };

  const checkAdmin = useCallback(async () => {
    const { data: userData, error } = await supabase
      .from("users_data")
      .select("*")
      .eq("user_uid", uid)
      .single();

    if (error) {
      console.error("Error fetching user role:", error);
    } else {
      setIsAdmin(userData?.position === "admin");
    }
  }, [uid]);

  const fetchActivities = useCallback(async () => {
    const { data, error } = await supabase
      .from("daily_activities")
      .select("*, users_data(*), daily_attaches(*)")
      .eq("user_id", isAdmin ? selectedUser : uid)
      .order("created_at", { ascending: false });

    if (error) {
      console.error("Error fetching daily activities:", error);
    } else {
      setActivities(data);
    }
  }, [isAdmin, selectedUser, uid]);

  useEffect(() => {
    if (uid) {
      checkAdmin();
      fetchActivities();
    }
  }, [uid, checkAdmin, fetchActivities]);

  useEffect(() => {
    if (selectedUser) {
      fetchActivities();
    }
  }, [selectedUser, fetchActivities]);

  const fetchUsers = async () => {
    const { data, error } = await supabase.from("users_data").select("*");

    if (error) {
      console.error("Error fetching users:", error);
    } else {
      setUsers(data);
    }
  };

  const handleActivityChange = (e) => {
    setNewActivity(e.target.value);
  };

  const handleTitleChange = (e) => {
    setNewTitle(e.target.value);
  };

  const handleAddActivity = async () => {
    if (newActivity.trim() === "") {
      return;
    }

    // Önce daily_activities tablosuna veri ekleyelim
    const { data, error } = await supabase
      .from("daily_activities")
      .insert([
        {
          user_id: uid,
          activity: newActivity,
          title: newTitle,
        },
      ])
      .select("*, users_data(*)");

    if (error) {
      console.error("Error adding activity:", error);
      return;
    }

    const newActivityId = data[0].id;

    // Eğer ekler varsa, bunları daily_attaches tablosuna ekleyelim
    if (uploadedFileUrl.length > 0) {
      const attachPromises = uploadedFileUrl.map((file) =>
        supabase.from("daily_attaches").insert({
          daily_activity_id: newActivityId,
          file_url: file.path,
          created_from: uid,
        })
      );

      try {
        await Promise.all(attachPromises);
      } catch (attachError) {
        console.error("Error adding attachments:", attachError);
      }
    }

    // Yeni aktiviteyi ve eklerini tekrar çekelim
    const { data: updatedActivity, error: fetchError } = await supabase
      .from("daily_activities")
      .select("*, users_data(*), daily_attaches(*)")
      .eq("id", newActivityId)
      .single();

    if (fetchError) {
      console.error("Error fetching updated activity:", fetchError);
    } else {
      setActivities([updatedActivity, ...activities]);
    }

    setNewActivity("");
    setNewTitle("");
    setUploadedFileUrl([]);
  };

  const handleFileChange = (e) => {
    handleUploadFile(e.target.files);
  };

  const handleUploadFile = async (files) => {
    if (!files || files.length === 0) {
      return;
    }

    setIsUploading(true);

    const newUploadedFiles = [];

    for (const file of files) {
      const unixTimestamp = Math.floor(Date.now() / 1000);
      const filePath = `public/${unixTimestamp}-${file.name}`;

      const { data, error } = await supabase.storage
        .from("sunton-crm-bucket")
        .upload(filePath, file);

      if (error) {
        console.error("Error uploading file:", error);
      } else {
        const { data: publicURL } = await supabase.storage
          .from("sunton-crm-bucket")
          .createSignedUrls([data?.path], 3000);

        newUploadedFiles.push({
          name: file.name,
          url: publicURL[0].signedUrl,
          path: data?.path,
        });
      }
    }

    setUploadedFileUrl((prevUrls) => [...prevUrls, ...newUploadedFiles]);
    setIsUploading(false);
  };

  const toast = useToast();

  const handleDeleteActivity = async (activityId) => {
    const { error } = await supabase
      .from("daily_activities")
      .delete()
      .eq("id", activityId);

    if (error) {
      console.error("Error deleting activity:", error);
      toast({
        title: "Hata",
        description: "Aktivite silinirken bir hata oluştu.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else {
      setActivities(
        activities.filter((activity) => activity.id !== activityId)
      );
      toast({
        title: "Başarılı",
        description: "Aktivite başarıyla silindi.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const getSignedUrl = async (path) => {
    if (signedUrls[path]) return;

    const { data, error } = await supabase.storage
      .from("sunton-crm-bucket")
      .createSignedUrl(path, 3000);

    if (error) {
      console.error("Error getting signed URL:", error);
    } else {
      setSignedUrls((prev) => ({ ...prev, [path]: data.signedUrl }));
    }
  };

  return (
    <Grid templateColumns="repeat(1, 1fr)" gap={4} >
      {isAdmin && (
        <GridItem colSpan={1} >
          <Tabs variant="enclosed" >
            <TabList justifyContent={"center"}>
              <Tab>Kendi Aktivitelerim</Tab>
              <Tab>Tüm Aktiviteler</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <DailyActivitiesContent
                  activities={activities.filter((a) => a.user_id === uid)}
                  handleAddActivity={handleAddActivity}
                  handleActivityChange={handleActivityChange}
                  handleTitleChange={handleTitleChange}
                  handleFileChange={handleFileChange}
                  newActivity={newActivity}
                  newTitle={newTitle}
                  isUploading={isUploading}
                  uploadedFileUrl={uploadedFileUrl}
                  pinnedBgColor={pinnedBgColor}
                  unpinnedBgColor={unpinnedBgColor}
                  handleDeleteActivity={handleDeleteActivity}
                  signedUrls={signedUrls}
                  getSignedUrl={getSignedUrl}
                />
              </TabPanel>
              <TabPanel>
                <Select
                  value={selectedUser}
                  onChange={(e) => setSelectedUser(e.target.value)}
                  mb={4}
                >
                  {users.map((user) => (
                    <option key={user.user_uid} value={user.user_uid}>
                      {user.name} {user.last_name}
                    </option>
                  ))}
                </Select>
                <DailyActivitiesContent
                  activities={activities}
                  handleAddActivity={handleAddActivity}
                  handleActivityChange={handleActivityChange}
                  handleTitleChange={handleTitleChange}
                  handleFileChange={handleFileChange}
                  newActivity={newActivity}
                  newTitle={newTitle}
                  isUploading={isUploading}
                  uploadedFileUrl={uploadedFileUrl}
                  handleDeleteActivity={handleDeleteActivity}
                  pinnedBgColor={pinnedBgColor}
                  unpinnedBgColor={unpinnedBgColor}
                  readOnly={selectedUser !== uid}
                  signedUrls={signedUrls}
                  getSignedUrl={getSignedUrl}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </GridItem>
      )}

      {!isAdmin && (
        <GridItem colSpan={1}>
          <DailyActivitiesContent
            activities={activities}
            handleAddActivity={handleAddActivity}
            handleActivityChange={handleActivityChange}
            handleTitleChange={handleTitleChange}
            handleFileChange={handleFileChange}
            newActivity={newActivity}
            newTitle={newTitle}
            isUploading={isUploading}
            uploadedFileUrl={uploadedFileUrl}
            handleDeleteActivity={handleDeleteActivity}
            pinnedBgColor={pinnedBgColor}
            unpinnedBgColor={unpinnedBgColor}
            signedUrls={signedUrls}
            getSignedUrl={getSignedUrl}
          />
        </GridItem>
      )}
    </Grid>
  );
};

const DailyActivitiesContent = ({
  activities,
  handleAddActivity,
  handleActivityChange,
  handleTitleChange,
  handleFileChange,
  newActivity,
  newTitle,
  isUploading,
  uploadedFileUrl,
  handleDeleteActivity,
  unpinnedBgColor,
  signedUrls,
  getSignedUrl,
  readOnly = false,
}) => {
  return (
    <>
      {!readOnly && (
        <Box bg="gray.50" p={4} borderRadius="md" boxShadow="sm" mb={4}>
          <FormControl>
            <FormLabel>Yeni Aktivite</FormLabel>
            <Input
              value={newTitle}
              onChange={handleTitleChange}
              placeholder="Başlık ekleyin"
              mb={2}
            />
            <Textarea
              value={newActivity}
              onChange={handleActivityChange}
              placeholder="Yeni aktivite ekleyin"
              mb={2}
            />
            <Flex alignItems="center" gap={2}>
              <Button colorScheme="green" onClick={handleAddActivity}>
                Ekle
              </Button>
              <Box position="relative">
                <Button as="label" htmlFor="file-input" leftIcon={<FaUpload />}>
                  Upload
                </Button>
                <Input
                  id="file-input"
                  type="file"
                  onChange={handleFileChange}
                  hidden
                />
              </Box>
              {isUploading && (
                <Flex alignItems="center">
                  <Progress size="xs" isIndeterminate flex={1} mr={2} />
                  <Text fontSize="sm">Yükleniyor</Text>
                </Flex>
              )}
            </Flex>
          </FormControl>
          <Flex flexWrap="wrap" mt={2} gap={2}>
            {uploadedFileUrl.map((file, index) => (
              <Tooltip
                key={index}
                label={file.name.split("-").slice(1).join("-")}
                fontSize="md"
              >
                <Button leftIcon={<AttachmentIcon />} size="sm">
                  <Link href={file.url} isExternal>
                    Ek {index + 1}
                  </Link>
                </Button>
              </Tooltip>
            ))}
          </Flex>
        </Box>
      )}

      <Heading fontSize="lg" mb={4}>
        Aktiviteler
      </Heading>
      {activities.map((activity) => (
        <Box
          key={activity.id}
          bg={unpinnedBgColor}
          p={4}
          mb={4}
          borderRadius="md"
          boxShadow="md"
        >
          <Flex alignItems="center" mb={3}>
            <Text fontWeight="600" width="80px">
              Başlık
            </Text>
            <Text flex={1}>{activity.title}</Text>
          </Flex>

          <Flex mb={3}>
            <Text fontWeight="600" width="80px">
              Aktivite
            </Text>
            <Text flex={1} whiteSpace="pre-wrap">
              {activity.activity}
            </Text>
          </Flex>
          {activity.daily_attaches && activity.daily_attaches.length > 0 && (
            <Flex mb={3}>
              <Text fontWeight="600" width="80px" alignSelf={"center"}>
                Ekler
              </Text>
              <Flex flex={1} flexWrap="wrap" gap={2}>
                {activity.daily_attaches.map((attach, index) => (
                  <Tooltip key={index} label={attach.file_url} fontSize="md">
                    <Button
                      leftIcon={<AttachmentIcon />}
                      size="sm"
                      variant="outline"
                      onClick={(e) => {
                        if (!signedUrls[attach.file_url]) {
                          e.preventDefault();
                          getSignedUrl(attach.file_url);
                        }
                      }}
                    >
                      <Link
                        href={signedUrls[attach.file_url] || "#"}
                        isExternal
                      >
                        Ek {index + 1}
                      </Link>
                    </Button>
                  </Tooltip>
                ))}
              </Flex>
            </Flex>
          )}
          <Divider my={2} />
          <Flex
            justifyContent="space-between"
            alignItems="center"
            fontSize="sm"
            color="gray.500"
          >
            <Text>
              {new Date(activity.created_at).toLocaleString("tr-TR", {
                day: "2-digit",
                month: "short",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
              })}
            </Text>
            <Flex alignItems="center">
              {activity.users_data && (
                <Flex alignItems="center" mr={2}>
                  <Avatar
                    name={`${activity.users_data.name} ${activity.users_data.last_name}`}
                    size="xs"
                    mr={2}
                  />
                  <Text>
                    {`${activity.users_data.name} ${activity.users_data.last_name}`}
                  </Text>
                </Flex>
              )}
              {!readOnly && (
                <IconButton
                  icon={<DeleteIcon />}
                  size="sm"
                  colorScheme="red"
                  onClick={() => handleDeleteActivity(activity.id)}
                  aria-label="Delete activity"
                />
              )}
            </Flex>
          </Flex>
        </Box>
      ))}
    </>
  );
};

export default DailyActivities;

// supabaseClient.js
import { createClient } from "@supabase/supabase-js";

const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;

export const supabase = createClient(
  "https://vugoakxenarrlobqizdq.supabase.co",
  supabaseKey,
  {
    realtime: {
      params: {
        eventsPerSecond: 10,
      },
    },
  }
);

import React, { useState, useEffect, useCallback, useMemo } from "react";
import { supabase } from "./supabaseClient";
import { v4 as uuidv4 } from "uuid";
import {
  Box,
  Button,
  VStack,
  HStack,
  Text,
  Flex,
  Heading,
  useColorModeValue,
  Icon,
  Input,
  FormControl,
  FormLabel,
  Select,
  Textarea,
  Badge,
  Tooltip,
  Table,
  Thead,
  Tbody,
  Tr,
  Grid,
  Th,
  Td,
  IconButton,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Collapse,
  SimpleGrid,
  GridItem,
} from "@chakra-ui/react";
import {
  FaPlus,
  FaEdit,
  FaTrash,
  FaHistory,
  FaDollarSign,
  FaCalendarAlt,
  FaClock,
  FaStickyNote,
  FaChevronDown,
  FaChevronUp,
} from "react-icons/fa";
import { FcMoneyTransfer } from "react-icons/fc";

const OfferManagement = ({ customerId }) => {
  const [offers, setOffers] = useState([]);
  const [machines, setMachines] = useState([]);
  const [selectedOfferHistory, setSelectedOfferHistory] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const [editingOffer, setEditingOffer] = useState(null);

  const formBgColor = useColorModeValue("blue.50", "blue.900");
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();
  const toast = useToast();

  const borderColor = useColorModeValue("gray.200", "gray.600");

  const fetchOffers = useCallback(async () => {
    const { data: offersData, error: offersError } = await supabase
      .from("offers")
      .select("*")
      .eq("customer_id", customerId)
      .order("created_at", { ascending: false });

    if (offersError) {
      console.error("Error fetching offers:", offersError);
      return;
    }

    const offersWithMachines = await Promise.all(
      offersData.map(async (offer) => {
        const { data: machinesData, error: machinesError } = await supabase
          .from("offer_machines")
          .select("*, machines(*)")
          .eq("offer_id", offer.id);

        if (machinesError) {
          console.error("Error fetching offer machines:", machinesError);
          return offer;
        }

        return { ...offer, machines: machinesData };
      })
    );

    setOffers(offersWithMachines);
  }, [customerId]);

  const fetchMachines = useCallback(async () => {
    const { data, error } = await supabase
      .from("machines")
      .select("*")
      .eq("customer_id", customerId);
    if (error) console.error("Error fetching machines:", error);
    else setMachines(data);
  }, [customerId]);

  useEffect(() => {
    fetchOffers();
    fetchMachines();
  }, [fetchOffers, fetchMachines]);

  const handleEdit = useCallback((offer) => {
    setEditingOffer({
      ...offer,
      machines: offer.machines.map((m) => ({
        machine_id: m.machine_id,
        quantity: m.quantity,
        unit_price: m.unit_price,
      })),
    });
    setIsFormOpen(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleFormSubmit = async (formData) => {
    console.log("Form data received:", formData);

    const validMachines = formData.machines.filter(
      (machine) => machine.machine_id && machine.quantity && machine.unit_price
    );

    console.log("Valid machines:", validMachines);

    const totalInvestmentAmount = validMachines.reduce(
      (total, machine) =>
        total + Number(machine.quantity) * Number(machine.unit_price),
      0
    );

    console.log("Total investment amount:", totalInvestmentAmount);

    const isNewOffer = !editingOffer?.id;
    const offerId = isNewOffer ? uuidv4() : editingOffer.id;

    const offerData = {
      id: offerId,
      customer_id: customerId,
      estimated_investment_date: formData.estimated_investment_date,
      total_investment_amount: totalInvestmentAmount,
      status: formData.status,
      payment_terms: formData.payment_terms,
      delivery_time: formData.delivery_time,
      warranty_period: formData.warranty_period,
      special_notes: formData.special_notes,
    };

    console.log("Offer data:", offerData);

    let error;

    if (isNewOffer) {
      // Yeni teklif oluştur
      const { error: offerError } = await supabase
        .from("offers")
        .insert(offerData);

      if (offerError) {
        console.error("Error creating new offer:", offerError);
        error = offerError;
      } else {
        // Makineleri ekle
        const machinesData = validMachines.map((machine) => ({
          offer_id: offerId,
          machine_id: machine.machine_id,
          quantity: machine.quantity,
          unit_price: machine.unit_price,
        }));

        console.log("Machines data to insert:", machinesData);

        const { error: machinesError } = await supabase
          .from("offer_machines")
          .insert(machinesData);

        if (machinesError) {
          console.error("Error inserting offer machines:", machinesError);
          error = machinesError;
        }
      }
    } else {
      // Mevcut teklifi güncelle
      console.log("Updating existing offer");
      const { error: updateError } = await supabase.rpc("update_offer", {
        p_offer_id: offerId,
        p_customer_id: customerId,
        p_estimated_investment_date: formData.estimated_investment_date,
        p_total_investment_amount: totalInvestmentAmount,
        p_status: formData.status,
        p_payment_terms: formData.payment_terms,
        p_delivery_time: formData.delivery_time,
        p_warranty_period: formData.warranty_period,
        p_special_notes: formData.special_notes,
        p_machines: validMachines,
      });

      if (updateError) {
        console.error("Error updating offer:", updateError);
        error = updateError;
      }
    }

    if (error) {
      console.error(
        `Error ${isNewOffer ? "creating" : "updating"} offer:`,
        error
      );
      toast({
        title: `Teklif ${isNewOffer ? "oluşturulamadı" : "güncellenemedi"}.`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    toast({
      title: `Teklif başarıyla ${isNewOffer ? "oluşturuldu" : "güncellendi"}.`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });

    // Eğer yeni bir teklif oluşturulduysa, geçmiş oluştur
    if (isNewOffer) {
      await createOfferHistory(offerId, {
        ...offerData,
        machines: validMachines,
      });
    }

    setIsFormOpen(false);
    setEditingOffer(null);
    fetchOffers();
  };

  const createOfferHistory = async (offerId, offerData) => {
    const { data: historyResult, error: historyError } = await supabase
      .from("offer_history")
      .insert({
        offer_id: offerId,
        customer_id: customerId,
        estimated_investment_date: offerData.estimated_investment_date,
        total_investment_amount: offerData.total_investment_amount,
        status: offerData.status,
        payment_terms: offerData.payment_terms,
        delivery_time: offerData.delivery_time,
        warranty_period: offerData.warranty_period,
        special_notes: offerData.special_notes,
      })
      .select()
      .single();

    if (historyError) {
      console.error("Error creating offer history:", historyError);
      return;
    }

    for (const machine of offerData.machines) {
      const { error: machineHistoryError } = await supabase
        .from("offer_machine_history")
        .insert({
          offer_history_id: historyResult.id,
          machine_id: machine.machine_id,
          quantity: machine.quantity,
          unit_price: machine.unit_price,
        });

      if (machineHistoryError) {
        console.error(
          "Error creating offer machine history:",
          machineHistoryError
        );
      }
    }
  };

  const getStatusBadge = useCallback((status) => {
    switch (status) {
      case "accepted":
        return <Badge colorScheme="green">Kabul Edildi</Badge>;
      case "rejected":
        return <Badge colorScheme="red">Reddedildi</Badge>;
      case "pending":
        return <Badge colorScheme="yellow">Beklemede</Badge>;
      default:
        return <Badge colorScheme="gray">Bilinmiyor</Badge>;
    }
  }, []);

  const fetchOfferHistory = useCallback(
    async (offerId) => {
      const { data, error } = await supabase
        .from("offer_history")
        .select(
          `
        *,
        offer_machine_history (
          *,
          machines (*)
        )
      `
        )
        .eq("offer_id", offerId)
        .order("created_at", { ascending: false });

      if (error) {
        console.error("Error fetching offer history:", error);
        return;
      }

      setSelectedOfferHistory(data);
      onModalOpen();
    },
    [onModalOpen]
  );

  const OfferCard = useCallback(
    ({ offer }) => (
      <Box
        bg={formBgColor}
        p={6}
        borderRadius="lg"
        boxShadow="md"
        borderWidth={1}
        borderColor={borderColor}
      >
        <Flex justify="space-between" align="center" mb={4}>
          <Heading size="md" fontWeight="bold">
            Teklif #{offer.id.slice(0, 8)}
          </Heading>
          {getStatusBadge(offer.status)}
        </Flex>
        <SimpleGrid align="stretch" spacing={4} columns={{ base: 1, md: 2 }}>
          <HStack>
            <Icon as={FaCalendarAlt} color="blue.500" />
            <Text fontWeight={600}>Tahmini Yatırım Tarihi:</Text>
            <Text>
              {" "}
              {new Date(offer.estimated_investment_date).toLocaleDateString()}
            </Text>
          </HStack>
          <HStack>
            <Icon as={FaDollarSign} color="green.500" />
            <Text fontWeight={600}>Toplam:</Text>
            <Text>{offer.total_investment_amount.toLocaleString()} USD</Text>
          </HStack>
          <HStack>
            <Icon as={FaClock} color="purple.500" />
            <Text fontWeight={600}>Teslim:</Text>
            <Text> {offer.delivery_time}</Text>
          </HStack>
          <HStack>
            <Icon as={FcMoneyTransfer} />
            <Text fontWeight={600}>Kapora: </Text>
            <Text>{offer.warranty_period}</Text>
          </HStack>
          {offer.special_notes && (
            <HStack>
              <Icon as={FaStickyNote} color="yellow.500" />
              <Text noOfLines={2}>{offer.special_notes}</Text>
            </HStack>
          )}
        </SimpleGrid>
        <Flex justify="space-between" mt={6}>
          <Tooltip label="Düzenle">
            <Button
              leftIcon={<FaEdit />}
              onClick={() => handleEdit(offer)}
              colorScheme="blue"
              variant="outline"
            >
              Düzenle
            </Button>
          </Tooltip>
          <Tooltip label="Geçmiş">
            <Button
              leftIcon={<FaHistory />}
              onClick={() => fetchOfferHistory(offer.id)}
              colorScheme="teal"
              variant="outline"
            >
              Geçmiş
            </Button>
          </Tooltip>
        </Flex>
      </Box>
    ),
    [formBgColor, borderColor, getStatusBadge, handleEdit, fetchOfferHistory]
  );

  const OfferForm = ({
    initialOffer,
    onSubmit,
    machines,
    formBgColor,
    borderColor,
  }) => {
    const [formState, setFormState] = useState(() => ({
      estimated_investment_date: initialOffer?.estimated_investment_date || "",
      payment_terms: initialOffer?.payment_terms || "",
      delivery_time: initialOffer?.delivery_time || "",
      warranty_period: initialOffer?.warranty_period || "",
      special_notes: initialOffer?.special_notes || "",
      status: initialOffer?.status || "pending",
      machines: initialOffer?.machines || [],
    }));

    useEffect(() => {
      setFormState({
        estimated_investment_date:
          initialOffer?.estimated_investment_date || "",
        payment_terms: initialOffer?.payment_terms || "",
        delivery_time: initialOffer?.delivery_time || "",
        warranty_period: initialOffer?.warranty_period || "",
        special_notes: initialOffer?.special_notes || "",
        status: initialOffer?.status || "pending",
        machines: initialOffer?.machines || [],
      });
    }, [initialOffer]);

    const handleInputChange = useCallback((e) => {
      const { name, value } = e.target;
      setFormState((prev) => ({
        ...prev,
        [name]: value,
      }));
    }, []);

    const handleMachineChange = useCallback((index, field, value) => {
      setFormState((prev) => {
        const updatedMachines = [...prev.machines];
        updatedMachines[index] = { ...updatedMachines[index], [field]: value };
        return { ...prev, machines: updatedMachines };
      });
    }, []);

    const addMachine = useCallback(() => {
      setFormState((prev) => ({
        ...prev,
        machines: [
          ...prev.machines,
          { machine_id: "", quantity: 1, unit_price: "" },
        ],
      }));
    }, []);

    const removeMachine = useCallback((index) => {
      setFormState((prev) => {
        const updatedMachines = [...prev.machines];
        updatedMachines.splice(index, 1);
        return { ...prev, machines: updatedMachines };
      });
    }, []);

    const handleSubmit = async (e) => {
      e.preventDefault();
      onSubmit(formState);
    };

    const machineInputs = useMemo(
      () =>
        formState.machines.map((machine, index) => (
          <React.Fragment key={index}>
            <GridItem colSpan={{ base: 1, lg: 3 }}>
              <FormControl w={"100%"}>
                <Select
                  value={machine.machine_id || ""}
                  onChange={(e) =>
                    handleMachineChange(index, "machine_id", e.target.value)
                  }
                >
                  <option value="">Makine Seçin</option>
                  {machines.map((m) => (
                    <option key={m.id} value={m.id}>
                      {m.series} - {m.model}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 1, lg: 3 }}>
              <FormControl w={"100%"}>
                <Input
                  type="number"
                  value={machine.quantity || ""}
                  onChange={(e) =>
                    handleMachineChange(index, "quantity", e.target.value)
                  }
                  placeholder="Adet"
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 1, lg: 3 }}>
              <FormControl w={"100%"}>
                <Input
                  type="number"
                  value={machine.unit_price || ""}
                  onChange={(e) =>
                    handleMachineChange(index, "unit_price", e.target.value)
                  }
                  placeholder="Birim Fiyat"
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 1, lg: 1 }}>
              <IconButton
                icon={<FaTrash />}
                onClick={() => removeMachine(index)}
                colorScheme="red"
                size="sm"
                w={"100%"}
              />
            </GridItem>
          </React.Fragment>
        )),
      [formState.machines, handleMachineChange, machines, removeMachine]
    );

    return (
      <Box
        bg={formBgColor}
        p={6}
        borderRadius="lg"
        boxShadow="md"
        borderWidth={1}
        borderColor={borderColor}
      >
        <Heading size="md" mb={4}>
          {initialOffer?.id
            ? `Teklif Düzenle #${initialOffer.id.slice(0, 8)}`
            : "Yeni Teklif Oluştur"}
        </Heading>
        <form onSubmit={handleSubmit}>
          <Grid gap={6} columns={{ base: 1, md: 2 }} align="stretch">
            <FormControl isRequired>
              <FormLabel>Tahmini Yatırım Tarihi</FormLabel>
              <Input
                type="date"
                name="estimated_investment_date"
                value={formState.estimated_investment_date}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Ödeme Koşulları</FormLabel>
              <Input
                name="payment_terms"
                value={formState.payment_terms}
                onChange={handleInputChange}
                placeholder="Örn: %30 peşin, kalan 6 taksit"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Teslim Süresi</FormLabel>
              <Input
                name="delivery_time"
                value={formState.delivery_time}
                onChange={handleInputChange}
                placeholder="Örn: 4-6 hafta"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Kapora Bedeli</FormLabel>
              <Input
                name="warranty_period"
                value={formState.warranty_period}
                onChange={handleInputChange}
                placeholder="Örn: 10000 USD"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Özel Notlar</FormLabel>
              <Textarea
                name="special_notes"
                value={formState.special_notes}
                onChange={handleInputChange}
                placeholder="Teklif ile ilgili özel notlar..."
              />
            </FormControl>
            <FormControl>
              <FormLabel>Durum</FormLabel>
              <Select
                name="status"
                value={formState.status}
                onChange={handleInputChange}
              >
                <option value="pending">Beklemede</option>
                <option value="accepted">Kabul Edildi</option>
                <option value="rejected">Reddedildi</option>
              </Select>
            </FormControl>
            <Heading as="h4" size="md" mb={2}>
              Makineler
            </Heading>
            <GridItem
              colSpan={{ base: 1, md: 2 }}
              w={"100%"}
              display={"flex"}
              alignItems={"center"}
              gap={2}
            >
              <Grid
                templateColumns={{
                  base: "repeat(1,1fr)",
                  lg: "repeat(10, 1fr)",
                }}
                gap={2}
                w={"100%"}
                alignItems={"center"}
              >
                {machineInputs}
              </Grid>
            </GridItem>
            <GridItem colSpan={{ base: 1, md: 2 }}>
              <Button
                leftIcon={<FaPlus />}
                onClick={addMachine}
                colorScheme="green"
                size="sm"
                w={"100%"}
              >
                Makine Ekle
              </Button>
            </GridItem>
            <GridItem colSpan={{ base: 1, md: 2 }}>
              <Button type="submit" colorScheme="blue" mt={4} width={"100%"}>
                {initialOffer?.id ? "Teklifi Güncelle" : "Teklif Oluştur"}
              </Button>
            </GridItem>
          </Grid>
        </form>
      </Box>
    );
  };

  return (
    <Box>
      <Flex justify="space-between" align="center" mb={6}>
        <Heading size="lg">Teklif Yönetimi</Heading>
        <Button
          leftIcon={isFormOpen ? <FaChevronUp /> : <FaChevronDown />}
          colorScheme="blue"
          onClick={() => {
            setIsFormOpen(!isFormOpen);
            if (!isFormOpen) {
              setEditingOffer({
                id: null,
                estimated_investment_date: "",
                total_investment_amount: "",
                status: "pending",
                payment_terms: "",
                delivery_time: "",
                warranty_period: "",
                special_notes: "",
                machines: [],
              });
            }
          }}
        >
          {isFormOpen ? "Formu Kapat" : "Yeni Teklif"}
        </Button>
      </Flex>

      <Collapse in={isFormOpen} animateOpacity>
        <Box mb={6}>
          <OfferForm
            initialOffer={editingOffer}
            onSubmit={handleFormSubmit}
            machines={machines}
            formBgColor={formBgColor}
            borderColor={borderColor}
          />
        </Box>
      </Collapse>

      <VStack spacing={6} align="stretch">
        {offers.map((offer) => (
          <OfferCard key={offer.id} offer={offer} />
        ))}
      </VStack>
      <Modal isOpen={isModalOpen} onClose={onModalClose} size="xl">
        <ModalOverlay />
        <ModalContent m={5}>
          <ModalHeader>Teklif Geçmişi</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedOfferHistory &&
              selectedOfferHistory.map((history, index) => (
                <Box
                  key={history.id}
                  mb={6}
                  p={4}
                  borderWidth={1}
                  borderRadius="md"
                >
                  <Text fontWeight="bold">
                    Versiyon {selectedOfferHistory.length - index}
                  </Text>
                  <Text>
                    Tarih: {new Date(history.created_at).toLocaleString()}
                  </Text>
                  <Text>Durum: {getStatusBadge(history.status)}</Text>
                  <Text>
                    Toplam Yatırım: {history.total_investment_amount} USD
                  </Text>
                  <Table size="sm" mt={2}>
                    <Thead>
                      <Tr>
                        <Th>Makine</Th>
                        <Th>Adet</Th>
                        <Th>Birim Fiyat</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {history.offer_machine_history.map((machine) => (
                        <Tr key={machine.id}>
                          <Td>
                            {machine.machines.series} - {machine.machines.model}
                          </Td>
                          <Td>{machine.quantity}</Td>
                          <Td>{machine.unit_price} USD</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Box>
              ))}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onModalClose}>
              Kapat
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default OfferManagement;

import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Heading,
  Text,
  Grid,
  GridItem,
  Textarea,
  FormControl,
  FormLabel,
  Button,
  Input,
  Link,
  Icon,
  Progress,
  Tooltip,
  Divider,
  Avatar,
  Switch,
  ButtonGroup,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Editable,
  EditableInput,
  EditablePreview,
  useEditableControls,
  Flex,
  EditableTextarea,
  useColorModeValue,
} from "@chakra-ui/react";
import { TiPin } from "react-icons/ti";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { FaUpload } from "react-icons/fa";
import { supabase } from "./supabaseClient";
import { AttachmentIcon, CloseIcon } from "@chakra-ui/icons";
import { RiAdminLine } from "react-icons/ri";
import { GiAutoRepair } from "react-icons/gi";
import { FaHandHoldingDollar } from "react-icons/fa6";
import { FaLock, FaGlobe } from "react-icons/fa";
import { FaEllipsisV, FaEdit, FaTrash } from "react-icons/fa";
import { AiOutlineEnter } from "react-icons/ai";
import { isPWA } from "./App";

import ChatComponent from './ChatComponent';

const CustomerActivities = ({ id, uid }) => {
  const [newActivity, setNewActivity] = useState("");
  const [newTitle, setNewTitle] = useState("");
  const [activities, setActivities] = useState([]);

  const [uploadedFileUrl, setUploadedFileUrl] = useState([]);
  const [refresh, setRefresh] = useState(1);
  const [isUploading, setIsUploading] = useState(false);
  const [signedUrls, setSignedUrls] = useState({});
  const [newVisibility, setNewVisibility] = useState("admin");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [editingActivity, setEditingActivity] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activityToDelete, setActivityToDelete] = useState(null);
  const itemsPerPage = 10; // You can adjust this number
  const [currentUser, setCurrentUser] = useState(null);
  const [editingAttachments, setEditingAttachments] = useState([]);
  const fileInputRef = useRef(null);


  const pinnedBgColor = useColorModeValue("purple.50", "purple.700");
  const unpinnedBgColor = useColorModeValue("gray.100", "gray.700");

  const getVisibilityIcon = (visibility) => {
    switch (visibility) {
      case "all":
        return <Icon as={FaGlobe} />; // Herkes ikonu
      case "admin":
        return <Icon as={FaLock} />; // Normal ikonu
      default:
        return null;
    }
  };

  const getIcon = (position) => {
    if (position) {
      switch (position.toLowerCase()) {
        case "servis":
          return GiAutoRepair;
        case "satis":
          return FaHandHoldingDollar;
        case "admin":
          return RiAdminLine;
        default:
          return position.toUpperCase();
      }
    }
  };

  const handleDeleteActivity = async () => {
    if (!activityToDelete) return;

    const { error } = await supabase
      .from("customer_activities")
      .delete()
      .eq("id", activityToDelete.id);

    if (error) {
      console.error("Error deleting activity:", error);
    } else {
      setActivities((prevActivities) =>
        prevActivities.filter((activity) => activity.id !== activityToDelete.id)
      );
      onClose();
    }
  };
  const handleEditActivityFileChange = async (e) => {
    const files = Array.from(e.target.files);
    setIsUploading(true);

    const newAttachments = [];

    for (const file of files) {
      const unixTimestamp = Math.floor(Date.now() / 1000);
      const filePath = `public/${unixTimestamp}-${file.name}`;

      const { data, error } = await supabase.storage
        .from("sunton-crm-bucket")
        .upload(filePath, file);

      if (error) {
        console.error("Error uploading file:", error);
      } else {
        newAttachments.push({
          name: file.name,
          path: data?.path,
        });
      }
    }

    setEditingAttachments((prev) => [...prev, ...newAttachments]);
    setIsUploading(false);
  };

  const handleEditActivity = async (activityId, newTitle, newActivity) => {
    try {
      const { error } = await supabase
        .from("customer_activities")
        .update({ title: newTitle, activity: newActivity })
        .eq("id", activityId)
        .select();

      if (error) throw error;

      // Yeni dosya eklemeleri
      for (const attachment of editingAttachments) {
        const { error: attachError } = await supabase
          .from("customer_attaches")
          .insert([
            {
              customer_activity_id: activityId,
              file_url: attachment.path,
              created_from: uid,
            },
          ]);
        if (attachError) throw attachError;
      }

      // Aktiviteyi ve eklerini yeniden çek
      const { data: updatedActivity, error: fetchError } = await supabase
        .from("customer_activities")
        .select(`*, customer_attaches(*), users_data(*)`)
        .eq("id", activityId)
        .single();

      if (fetchError) throw fetchError;

      setActivities((prevActivities) =>
        prevActivities.map((activity) =>
          activity.id === activityId ? updatedActivity : activity
        )
      );

      setEditingActivity(null);
      setEditingAttachments([]);
    } catch (error) {
      console.error("Error in handleEditActivity:", error);
      // Burada kullanıcıya bir hata mesajı gösterebilirsiniz
    }
  };
  const sortActivities = (activities) => {
    return [...activities].sort((a, b) => {
      if (a.is_pinned === b.is_pinned) {
        // Eğer her iki aktivite de pinli veya pinsizse, tarihe göre sırala
        return new Date(b.created_at) - new Date(a.created_at);
      }
      // Pinli aktiviteleri üste çıkar
      return a.is_pinned ? -1 : 1;
    });
  };
  useEffect(() => {
    const handleRealtimeChanges = async (payload) => {
      const { eventType, new: newRecord, old: oldRecord } = payload;

      switch (eventType) {
        case "INSERT":
          if (currentPage === 1) {
            const { data, error } = await supabase
              .from("customer_activities")
              .select(`*, customer_attaches(*), users_data(*)`)
              .eq("id", newRecord.id)
              .single();

            if (error) {
              console.error("Error fetching new activity:", error);
            } else {
              setActivities((prevActivities) => {
                const pinnedActivities = prevActivities.filter(
                  (act) => act.is_pinned
                );
                const unpinnedActivities = prevActivities.filter(
                  (act) => !act.is_pinned
                );
                const updatedUnpinnedActivities = [data, ...unpinnedActivities];
                const updatedActivities = [
                  ...pinnedActivities,
                  ...updatedUnpinnedActivities,
                ];

                if (updatedActivities.length > itemsPerPage) {
                  updatedActivities.pop();
                }

                return updatedActivities;
              });
            }
          }
          setTotalPages((prevTotalPages) =>
            Math.ceil((prevTotalPages * itemsPerPage + 1) / itemsPerPage)
          );
          break;
        case "UPDATE":
          setActivities((prevActivities) =>
            prevActivities.map((activity) =>
              activity.id === newRecord.id
                ? { ...activity, ...newRecord }
                : activity
            )
          );
          break;
        case "DELETE":
          if (currentPage === 1) {
            setActivities((prevActivities) =>
              prevActivities.filter((activity) => activity.id !== oldRecord.id)
            );
          }
          setTotalPages((prevTotalPages) =>
            Math.max(
              1,
              Math.ceil((prevTotalPages * itemsPerPage - 1) / itemsPerPage)
            )
          );
          break;
        default:
          console.log("Unhandled event type:", eventType);
      }
    };
    const fetchCurrentUser = async () => {
      const { data, error } = await supabase
        .from("users_data")
        .select("*")
        .eq("user_uid", uid)
        .single();

      if (error) {
        console.error("Error fetching current user:", error);
      } else {
        setCurrentUser(data);
      }
    };

    const fetchCustomerActivities = async (page = 1) => {
      const from = (page - 1) * itemsPerPage;
      const to = from + itemsPerPage - 1;

      const { data, error, count } = await supabase
        .from("customer_activities")
        .select(
          `*,
            customer_attaches(*),
            users_data(*)`,
          { count: "exact" }
        )
        .eq("customer_id", id)
        .order("is_pinned", { ascending: false })
        .order("created_at", { ascending: false })
        .range(from, to);

      if (error) {
        console.error("Error fetching customer activities:", error);
      } else {
        setActivities(sortActivities(data));
        setTotalPages(Math.ceil(count / itemsPerPage));
      }
    };
    fetchCurrentUser();
    fetchCustomerActivities(currentPage);

    // Realtime subscription
    const subscription = supabase
      .channel("customer_activities_changes")
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "customer_activities",
          filter: `customer_id=eq.${id}`,
        },
        (payload) => {
          handleRealtimeChanges(payload);
        }
      )
      .subscribe();

    // Cleanup function
    return () => {
      subscription.unsubscribe();
    };
  }, [id, currentPage, uid]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    const getSignedUrls = async () => {
      const paths = activities.flatMap(
        (activity) =>
          activity.customer_attaches?.map((attach) => attach.file_url) || []
      );

      if (paths.length === 0) return;

      try {
        const { data, error } = await supabase.storage
          .from("sunton-crm-bucket")
          .createSignedUrls(paths, 300);

        if (error) {
          console.error("Error getting signed URLs:", error);
          return;
        }

        const urls = {};
        data.forEach((urlData, index) => {
          urls[paths[index]] = urlData.signedUrl;
        });

        setSignedUrls(urls);
      } catch (error) {
        console.error("Error getting signed URLs:", error);
      }
    };

    getSignedUrls();
  }, [activities.length, activities]);

  const handleActivityChange = (e) => {
    setNewActivity(e.target.value);
  };
  const handleTitleChange = (e) => {
    setNewTitle(e.target.value);
  };

  const handlePinActivity = async (activityId) => {
    try {
      const { error } = await supabase.rpc("update_activity_pin_status", {
        activity_id: activityId,
        user_id: uid,
      });

      if (error) throw error;
    } catch (error) {
      console.error("Error in handlePinActivity:", error);
    }
  };

  const handleAddActivity = async () => {
    if (newActivity.trim() === "") {
      return;
    }

    const { data, error } = await supabase
      .from("customer_activities")
      .insert([
        {
          customer_id: id,
          activity: newActivity,
          title: newTitle,
          created_from: uid,
          visibility: newVisibility,
          is_pinned: false,
        },
      ])
      .select("*,users_data(*)");

    if (error) {
      console.error("Error adding activity:", error);
    } else {
      const activityId = data[0].id;
      await handleAddAttaches(activityId);

      // Burada aktiviteleri güncellemeye gerek yok, realtime subscription bunu halledecek
      setNewActivity("");
      setNewTitle("");
      setRefresh(refresh + 1);
      setUploadedFileUrl([]);
    }
  };

  const handleAddAttaches = async (activity_id) => {
    const attachPromises = uploadedFileUrl.map(async (file) => {
      const { data, error } = await supabase
        .from("customer_attaches")
        .insert([
          {
            customer_activity_id: activity_id,
            file_url: file.path,
            created_from: uid,
          },
        ])
        .select();

      if (error) {
        console.error("Error adding attachment:", error);
      }
      return data?.[0];
    });

    const newAttaches = await Promise.all(attachPromises);
    return newAttaches.filter(Boolean);
  };

  const handleFileChange = (e) => {
    handleUploadFile(e.target.files);
  };

  const handleUploadFile = async (files) => {
    if (!files || files.length === 0) {
      return;
    }

    setIsUploading(true);

    const newUploadedFiles = [];

    for (const file of files) {
      const unixTimestamp = Math.floor(Date.now() / 1000);
      const filePath = `public/${unixTimestamp}-${file.name}`;

      const { data, error } = await supabase.storage
        .from("sunton-crm-bucket")
        .upload(filePath, file);

      if (error) {
        console.error("Error uploading file:", error);
      } else {
        const { data: publicURL } = await supabase.storage
          .from("sunton-crm-bucket")
          .createSignedUrls([data?.path], 3000);

        newUploadedFiles.push({
          name: file.name,
          url: publicURL[0].signedUrl,
          path: data?.path,
        });
      }
    }

    setUploadedFileUrl((prevUrls) => [...prevUrls, ...newUploadedFiles]);
    setIsUploading(false);
  };

  function EditableControls({ activity, editingActivity }) {
    const { getEditButtonProps } = useEditableControls();
    getEditButtonProps();

    return (
      <ButtonGroup justifyContent="end" size="sm">
        <IconButton
          icon={<AiOutlineEnter />}
          size="sm"
          backgroundColor={"yellow.400"}
          onClick={() =>
            handleEditActivity(
              activity.id,
              editingActivity.title,
              editingActivity.activity
            )
          }
        />
        <IconButton
          icon={<CloseIcon />}
          size="sm"
          backgroundColor={"yellow.400"}
          onClick={() => {
            setEditingActivity(null);
            setEditingAttachments([]);
          }}
        />
        <IconButton
          icon={<AttachmentIcon />}
          size="sm"
          backgroundColor={"yellow.400"}
          onClick={() => fileInputRef.current.click()}
        />
        <Input
          type="file"
          multiple
          hidden
          ref={fileInputRef}
          onChange={handleEditActivityFileChange}
        />
      </ButtonGroup>
    );
  }

  const isAdmin = () => {
    return currentUser?.position?.toLowerCase() === "admin";
  };

  const canModifyActivity = (activity) => {
    return activity.created_from === uid || isAdmin();
  };
  const getSignedUrl = async (path) => {
    const { data, error } = await supabase.storage
      .from("sunton-crm-bucket")
      .createSignedUrl(path, 3000);

    if (error) {
      console.error("Error getting signed URL:", error);
    } else {
      setSignedUrls((prev) => ({ ...prev, [path]: data.signedUrl }));
    }
  };

  return (
    <Grid
      templateColumns="repeat(1, 1fr)"
      gap={4}
      bg={isPWA ? "transparent" : "white"}
    >
      <GridItem colSpan={3}>
        <Box bg="gray.50" p={4} borderRadius="md" boxShadow="sm">
          <FormControl>
            <Flex justifyContent="space-between" alignItems="center" mb={2}>
              <FormLabel mb={0}>Yeni İşlem</FormLabel>
              <Switch
                colorScheme="teal"
                size="md"
                isChecked={newVisibility === "all"}
                onChange={() =>
                  setNewVisibility(newVisibility === "admin" ? "all" : "admin")
                }
              >
                {newVisibility === "admin"
                  ? getVisibilityIcon("admin")
                  : getVisibilityIcon("all")}
              </Switch>
            </Flex>
            <Input
              value={newTitle}
              onChange={handleTitleChange}
              placeholder="Başlık ekleyin"
              mb={2}
            />
            <Textarea
              value={newActivity}
              onChange={handleActivityChange}
              placeholder="Yeni işlem ekleyin"
              mb={2}
            />
            <Flex alignItems="center" gap={2}>
              <Button colorScheme="green" onClick={handleAddActivity}>
                Ekle
              </Button>
              <Box position="relative">
                <Button as="label" htmlFor="file-input" leftIcon={<FaUpload />}>
                  Upload
                </Button>
                <Input
                  id="file-input"
                  type="file"
                  onChange={handleFileChange}
                  hidden
                />
              </Box>
              {isUploading && (
                <Flex alignItems="center">
                  <Progress size="xs" isIndeterminate flex={1} mr={2} />
                  <Text fontSize="sm">Yükleniyor</Text>
                </Flex>
              )}
            </Flex>
          </FormControl>
          <Flex flexWrap="wrap" mt={2} gap={2}>
            {uploadedFileUrl.map((file, index) => (
              <Tooltip
                key={index}
                label={file.name.split("-").slice(1).join("-")}
                fontSize="md"
              >
                <Button leftIcon={<AttachmentIcon />} size="sm">
                  <Link href={file.url} isExternal>
                    Ek {index + 1}
                  </Link>
                </Button>
              </Tooltip>
            ))}
          </Flex>
        </Box>
      </GridItem>

      <GridItem colSpan={3}>
        <Heading fontSize="lg" mb={4}>
          İşlemler
        </Heading>
        {activities.map((activity) => (
          <Box
            key={activity.id}
            bg={activity.is_pinned ? pinnedBgColor : unpinnedBgColor}
            p={4}
            mb={4}
            borderRadius="md"
            boxShadow="md"
            borderLeft={activity.is_pinned ? "4px solid" : "none"}
            borderColor={activity.is_pinned ? "purple.400" : "gray"}
          >
            <Flex alignItems="center" mb={3}>
              <Text fontWeight="600" width="80px" alignSelf={"center"}>
                Başlık
              </Text>
              <Box
                flex={1}
                border="1px solid"
                borderColor="gray.200"
                borderRadius="md"
                p={2}
                display="flex"
                alignItems="flex-start"
                overflow="hidden"
              >
                {editingActivity?.id === activity.id ? (
                  <Editable defaultValue={activity.title} width="100%">
                    <EditablePreview
                      whiteSpace="pre-wrap"
                      wordBreak="break-word"
                      width="100%"
                    />
                    <EditableInput
                      onChange={(e) =>
                        setEditingActivity({
                          ...editingActivity,
                          title: e.target.value,
                        })
                      }
                      whiteSpace="pre-wrap"
                      wordBreak="break-word"
                    />
                  </Editable>
                ) : (
                  <Text
                    whiteSpace="pre-wrap"
                    wordBreak="break-word"
                    width="100%"
                  >
                    {activity.title}
                  </Text>
                )}
              </Box>
              <Box display={"flex"}>
                <Flex
                  ml={2}
                  alignItems={"center"}
                  h={"100%"}
                  flexDirection={"row"}
                  gap={2}
                >
                  <Box
                    minW={"35px"}
                    minH={"35px"}
                    backgroundColor={"yellow.400"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    display={"flex"}
                    borderRadius={"md"}
                  >
                    <Icon as={getIcon(activity.users_data.position)} />
                  </Box>
                  <Box
                    minW={"35px"}
                    minH={"35px"}
                    backgroundColor={"yellow.400"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    display={"flex"}
                    borderRadius={"md"}
                  >
                    <Tooltip
                      label={
                        activity.visibility === "all"
                          ? "Herkes görebilir"
                          : "Sadece yetkililer görebilir"
                      }
                    >
                      <Box>{getVisibilityIcon(activity.visibility)}</Box>
                    </Tooltip>
                  </Box>
                  {canModifyActivity(activity) && (
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        icon={<FaEllipsisV />}
                        size="sm"
                        variant="ghost"
                      />
                      <MenuList>
                        <MenuItem
                          icon={<FaEdit />}
                          onClick={() => setEditingActivity(activity)}
                        >
                          Düzenle
                        </MenuItem>
                        <MenuItem
                          icon={<FaTrash />}
                          onClick={() => {
                            setActivityToDelete(activity);
                            onOpen();
                          }}
                        >
                          Sil
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  )}
                </Flex>
              </Box>
            </Flex>

            <Flex mb={3}>
              <Text fontWeight="600" width="80px" alignSelf={"center"}>
                İşlem
              </Text>

              <Box
                flex={1}
                border="1px solid"
                borderColor="gray.200"
                borderRadius="md"
                p={2}
                position="relative"
              >
                {editingActivity?.id === activity.id ? (
                  <Flex direction="column" width="100%">
                    <Editable
                      defaultValue={activity.activity}
                      startWithEditView={true}
                      width="100%"
                    >
                      <EditablePreview width="100%" />
                      <EditableTextarea
                        width="100%"
                        onChange={(e) =>
                          setEditingActivity({
                            ...editingActivity,
                            activity: e.target.value,
                          })
                        }
                      />
                    </Editable>
                  </Flex>
                ) : (
                  <Text
                    whiteSpace="pre-wrap"
                    wordBreak="break-word"
                    width="100%"
                  >
                    {activity.activity}
                  </Text>
                )}
              </Box>
            </Flex>

            {editingActivity?.id === activity.id ? (
              <Flex mb={3} w={"100%"} justifyContent={"space-between"}>
                <>
                  <Flex w={"100%"}>
                    <Editable
                      w={"100%"}
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                    >
                      <Flex
                        flexWrap="wrap"
                        width="100%"
                        alignContent={"center"}
                      >
                        {editingAttachments.length > 0 && (
                          <Text
                            fontWeight="600"
                            width="80px"
                            alignSelf={"center"}
                          >
                            Yeni Ekler
                          </Text>
                        )}
                        <Flex flexWrap="wrap" alignContent={"center"} gap={2}>
                          {editingAttachments.map((attachment, index) => (
                            <>
                              <Button
                                key={index}
                                leftIcon={<AttachmentIcon />}
                                size="sm"
                                bg={"purple.500"}
                                color={"white"}
                              >
                                Ek {index + 1}
                              </Button>
                            </>
                          ))}
                        </Flex>
                        {isUploading && (
                          <Flex alignItems="center">
                            <Progress
                              size="xs"
                              isIndeterminate
                              flex={1}
                              mr={2}
                            />
                            <Text fontSize="sm">Yükleniyor</Text>
                          </Flex>
                        )}
                      </Flex>
                      <Flex justifyContent="end" width="100%" mt={2}>
                        <EditableControls
                          activity={activity}
                          editingActivity={editingActivity}
                        />
                      </Flex>
                    </Editable>
                  </Flex>
                </>
              </Flex>
            ) : (
              <></>
            )}

            {(activity.customer_attaches?.length > 0 ||
              editingAttachments.length > 0) && (
              <Flex mb={3}>
                <Text fontWeight="600" width="80px" alignSelf={"center"}>
                  Ekler
                </Text>
                <Flex flex={1} flexWrap="wrap" gap={2}>
                  {activity.customer_attaches?.map((attach, index) => (
                    <Tooltip key={index} label={attach.file_url} fontSize="md">
                      <Button
                        leftIcon={<AttachmentIcon />}
                        size="sm"
                        variant="outline"
                        onClick={(e) => {
                          if (!signedUrls[attach.file_url]) {
                            e.preventDefault();
                            getSignedUrl(attach.file_url);
                          }
                        }}
                      >
                        <Link
                          href={signedUrls[attach.file_url] || "#"}
                          isExternal
                        >
                          Ek {index + 1}
                        </Link>
                      </Button>
                    </Tooltip>
                  ))}
                </Flex>
              </Flex>
            )}

            <Divider my={2} />
            <Flex
              justifyContent="space-between"
              alignItems="center"
              fontSize="sm"
              color="gray.500"
            >
              <Text>
                {(() => {
                  const utcDate = new Date(activity.created_at);
                  const localDate = new Date(
                    utcDate.getTime() - utcDate.getTimezoneOffset() * 60000
                  );

                  return `${localDate.toLocaleDateString("tr-TR", {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                  })} ${localDate.toLocaleTimeString("tr-TR", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false,
                  })}`;
                })()}
              </Text>
              <Box
                display={"flex"}
                flexDirection={"row"}
                alignItems="center"
                gap={2}
              >
                <IconButton
                  icon={<TiPin />}
                  size={"sm"}
                  isRound={true}
                  colorScheme={activity.is_pinned ? "blue" : "gray"}
                  onClick={() =>
                    handlePinActivity(activity.id, activity.is_pinned)
                  }
                  aria-label={
                    activity.is_pinned ? "Unpin activity" : "Pin activity"
                  }
                />
                {activity.users_data && (
                  <Flex alignItems="center">
                    <Avatar
                      name={`${activity.users_data.name} ${activity.users_data.last_name}`}
                      size="xs"
                      mr={2}
                    />
                    <Text>
                      {`${activity.users_data.name} ${activity.users_data.last_name}`}
                    </Text>
                  </Flex>
                )}
              </Box>
            </Flex>
            <ChatComponent activityId={activity.id} currentUserId={uid} />
          </Box>
        ))}

        <Flex justifyContent="center" my={4}>
          <ButtonGroup isAttached variant="outline">
            <IconButton
              icon={<ChevronLeftIcon />}
              onClick={() => handlePageChange(currentPage - 1)}
              isDisabled={currentPage === 1}
            />
            <Button isDisabled>{currentPage}</Button>
            <IconButton
              icon={<ChevronRightIcon />}
              onClick={() => handlePageChange(currentPage + 1)}
              isDisabled={currentPage === totalPages}
            />
          </ButtonGroup>
        </Flex>
      </GridItem>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>İşlemi Sil</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Bu işlemi silmek istediğinizden emin misiniz? Bu işlem geri
            alınamaz.
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleDeleteActivity}>
              Sil
            </Button>
            <Button variant="ghost" onClick={onClose}>
              İptal
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Grid>
  );
};

export default CustomerActivities;

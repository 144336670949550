import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Box,
  VStack,
  HStack,
  Text,
  Avatar,
  Textarea,
  Button,
  Flex,
  useColorModeValue,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { tr } from "date-fns/locale";
import { supabase } from "./supabaseClient";
import { FaComment } from "react-icons/fa";

const ChatComponent = ({ activityId, currentUserId }) => {
  const [comments, setComments] = useState([]);
  const [commentReads, setCommentReads] = useState({});
  const [newComment, setNewComment] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const commentsEndRef = useRef(null);

  const myMessageBgColor = useColorModeValue("blue.100", "blue.700");
  const otherMessageBgColor = useColorModeValue("green.100", "green.700");

  const fetchComments = useCallback(async () => {
    const { data, error } = await supabase
      .from("activity_comments")
      .select(
        `
        *,
        users_data(*),
        comment_reads!left(user_id, read_at)
      `
      )
      .eq("activity_id", activityId)
      .order("created_at", { ascending: true });

    if (error) {
      console.error("Error fetching comments:", error);
    } else {
      setComments(data);

      // Process comment reads
      const reads = {};
      data.forEach((comment) => {
        reads[comment.id] = comment.comment_reads.reduce((acc, read) => {
          acc[read.user_id] = read.read_at;
          return acc;
        }, {});
      });
      setCommentReads(reads);
    }
  }, [activityId]);

  const markCommentAsRead = useCallback(
    async (commentId) => {
      const { error } = await supabase.from("comment_reads").upsert(
        {
          comment_id: commentId,
          user_id: currentUserId,
        },
        { onConflict: ["comment_id", "user_id"] }
      );

      if (error) {
        console.error("Error marking comment as read:", error);
      }
    },
    [currentUserId]
  );

  useEffect(() => {
    fetchComments();

    const commentSubscription = supabase
      .channel("activity_comments_changes")
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "activity_comments",
          filter: `activity_id=eq.${activityId}`,
        },
        (payload) => {
          fetchComments();
          if (isOpen && payload.eventType === "INSERT") {
            markCommentAsRead(payload.new.id);
          }
        }
      )
      .subscribe();

    const readSubscription = supabase
      .channel("comment_reads_changes")
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "comment_reads",
        },
        () => {
          fetchComments();
        }
      )
      .subscribe();

    return () => {
      commentSubscription.unsubscribe();
      readSubscription.unsubscribe();
    };
  }, [fetchComments, activityId, isOpen, markCommentAsRead]);

  useEffect(() => {
    if (isOpen && commentsEndRef.current) {
      commentsEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [comments, isOpen]);

  const handleAddComment = async () => {
    if (newComment.trim() === "") return;

    const { error } = await supabase
      .from("activity_comments")
      .insert([
        {
          activity_id: activityId,
          user_id: currentUserId,
          comment: newComment,
        },
      ])
      .select();

    if (error) {
      console.error("Error adding comment:", error);
    } else {
      setNewComment("");
      // The new comment will be fetched and displayed by the subscription
    }
  };

  const markAllCommentsAsRead = async () => {
    const unreadComments = comments.filter(
      (comment) =>
        comment.user_id !== currentUserId &&
        !commentReads[comment.id]?.[currentUserId]
    );

    if (unreadComments.length === 0) return;

    const reads = unreadComments.map((comment) => ({
      comment_id: comment.id,
      user_id: currentUserId,
    }));

    const { error } = await supabase
      .from("comment_reads")
      .upsert(reads, { onConflict: ["comment_id", "user_id"] });

    if (error) {
      console.error("Error marking comments as read:", error);
    }
  };

  const isCommentRead = (comment) => {
    return (
      comment.user_id === currentUserId ||
      !!commentReads[comment.id]?.[currentUserId]
    );
  };

  const toggleChat = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      markAllCommentsAsRead();
    }
  };

  const renderComments = () => {
    let lastDate = null;
    let hasUnreadDivider = false;

    return (
      <VStack align="stretch" spacing={2} mt={2}>
        {comments.map((comment) => {
          const commentDate = new Date(comment.created_at);
          const showDateDivider =
            lastDate === null || !isSameDay(lastDate, commentDate);
          const isUnread = !isCommentRead(comment);
          const showUnreadDivider = isUnread && !hasUnreadDivider;

          if (showUnreadDivider) {
            hasUnreadDivider = true;
          }

          lastDate = commentDate;

          const isCurrentUser = comment.user_id === currentUserId;

          return (
            <React.Fragment key={comment.id}>
              {showDateDivider && (
                <Flex justify="center" my={2}>
                  <Text
                    fontSize="sm"
                    bg="gray.200"
                    px={2}
                    py={1}
                    borderRadius="full"
                  >
                    {format(commentDate, "d MMMM yyyy", { locale: tr })}
                  </Text>
                </Flex>
              )}
              {showUnreadDivider && (
                <Flex justify="center" my={2}>
                  <Text fontSize="sm" color="red.500" fontWeight="bold">
                    Okunmamış mesajlar
                  </Text>
                </Flex>
              )}
              <Flex justify={isCurrentUser ? "flex-end" : "flex-start"}>
                <Box maxW="70%">
                  <Flex
                    direction="column"
                    alignItems={isCurrentUser ? "flex-end" : "flex-start"}
                  >
                    <Box
                      bg={
                        isCurrentUser ? myMessageBgColor : otherMessageBgColor
                      }
                      p={2}
                      borderRadius="lg"
                    >
                      <Text fontSize="sm">{comment.comment}</Text>
                    </Box>
                    <Flex
                      align="center"
                      mt={1}
                      flexDirection={isCurrentUser ? "row-reverse" : "row"}
                    >
                      <Avatar
                        size="xs"
                        name={`${comment.users_data.name} ${comment.users_data.last_name}`}
     
                        alignContent={"center"}
                      />
                      <Text fontSize="xs" fontWeight="bold" mx={2}>
                        {`${comment.users_data.name} ${comment.users_data.last_name}`}
                      </Text>
                    </Flex>
                    <Flex
                      justify={isCurrentUser ? "flex-end" : "flex-start"}
                      align="center"
                      width="100%"
                      mt={1}
                    >
                      <Text fontSize="xs" color="gray.500">
                        {format(new Date(comment.created_at), "HH:mm")}
                      </Text>
                      {isCurrentUser && (
                        <Text
                          fontSize="xs"
                          ml={1}
                          color={
                            Object.keys(commentReads[comment.id] || {}).length >
                            1
                              ? "blue.500"
                              : "gray.500"
                          }
                        >
                          {Object.keys(commentReads[comment.id] || {}).length >
                          1
                            ? "✓✓"
                            : "✓"}
                        </Text>
                      )}
                    </Flex>
                  </Flex>
                </Box>
              </Flex>
            </React.Fragment>
          );
        })}
        <div ref={commentsEndRef} />
      </VStack>
    );
  };

  const isSameDay = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  return (
    <Box>
      <Button leftIcon={<FaComment />} size="sm" onClick={toggleChat}>
        Yorumlar ({comments.length})
        {comments.some(
          (comment) =>
            !isCommentRead(comment) && comment.user_id !== currentUserId
        ) && (
          <Box as="span" ml={2} color="red.500" fontWeight="bold">
            •
          </Box>
        )}
      </Button>

      {isOpen && (
        <Box
          m={2}
          maxH="1000px"
          overflowY="auto"
          p={2}
          backgroundColor={"white"}
          borderRadius={"md"}
          sx={{
            "::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {renderComments()}
          <HStack mt={2}>
            <Textarea
              placeholder="Yorum ekle..."
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  handleAddComment();
                }
              }}
            />
            <Button onClick={handleAddComment}>Gönder</Button>
          </HStack>
        </Box>
      )}
    </Box>
  );
};

export default ChatComponent;
